import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ProfileHeader} from '../profile/ProfileHeader'
import Flower from './components/flowerDefined/flower'
import OrganizationFlower from './components/organizationFlower/OrganizationFlower'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/flower/defines',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const FlowerPage: React.FC = () => {
  return (
    <>
      {/* <ProfileHeader /> */}
      <Switch>
        <Route path='/crafted/pages/flower/defines'>
          {/* <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle> */}
          <Flower />
        </Route>
        <Route path='/crafted/pages/flower/organization'>
          <OrganizationFlower />
        </Route>

        <Redirect from='/crafted/pages/profile' exact={true} to='/crafted/pages/profile/overview' />
        <Redirect to='/crafted/pages/profile/overview' />
      </Switch>
    </>
  )
}

export default FlowerPage
