import {useDispatch, useSelector} from 'react-redux'
import {handle_variables, sellState} from '../../redux/sellSlice'
import {authState} from '../../../auth/redux/AuthSlice'
import {useEffect, useState} from 'react'
import {Descriptions, Table} from 'antd'
import {Button, Col, Container, Row} from 'react-bootstrap-v5'
import DefaultDropDown from '../../../../components/DefaultDropDown'

const SellReport = () => {
  const dispatch = useDispatch()

  const state = useSelector(sellState)
  const authS = useSelector(authState)

  const [tableData, setTableData] = useState<any>([])
  const [data, setData] = useState([
    {title: 'مرتب بودن سرگل ها در دسته', amount: 0, flowers: [], description: ''},
    {title: 'یک دست بودن پایین شاخه ها در دسته', amount: 0, flowers: [], description: ''},
    {title: 'کش پایین دسته ها', amount: 0, flowers: [], description: ''},
    {title: 'یک دست بودن غنچه ها در دسته', amount: 0, flowers: [], description: ''},
    {title: 'جدا بودن گل های لک دار از گل خوب', amount: 0, flowers: [], description: ''},
    {title: 'شکستگی سر گل در دسته', amount: 0, flowers: [], description: ''},
    {title: 'شل کردگی سرگل', amount: 0, flowers: [], description: ''},
  ])
  const [flowers, setFlowers] = useState<any>([])

  const farmerUser = authS?.user?.organization?.organizationType?.id == 1 ? true : false

  const columns: any = [
    {
      title: 'عنوان',
      dataIndex: 'title',
      key: 'title',
      // render: (text) => <a>{text}</a>,
    },
    {
      title: 'گل',
      dataIndex: 'flowers',
      key: 'flowers',
      // responsive: ['lg'],
    },
    {
      title: 'تعداد',
      dataIndex: 'amount',
      key: 'amount',
      // responsive: ['lg'],
    },

    {
      title: 'توضیحات',
      dataIndex: 'description',
      key: 'description',
      // responsive: ['lg'],
    },
  ]

  useEffect(() => {
    if (
      state?.basketUpdatedefaultValue?.saleRequests?.length > 0 &&
      state?.basketUpdatedefaultValue?.status != 1
    ) {
      state?.basketUpdatedefaultValue.saleRequests?.map((item: any) => {
        if (item?.flower?.id != 1) {
          setFlowers((prev: any) => [...prev, item?.flower])
        }
      })
    }

    if (state?.basketUpdatedefaultValue?.status == 1) {
      setTableData([])

      state?.basketUpdatedefaultValue?.basketReports.map((x: any) => {
        let flowersTitle = ''
        let data = {
          key: x?.id,
          title: x?.title,
          amount: x?.amount,
          description: x?.description,
          flowers: '',
        }

        x.flowers.map((item: any) => {
          flowersTitle = flowersTitle + ' - ' + item?.title
        })

        data.flowers = flowersTitle.substring(2, flowersTitle.length)

        setTableData((prevArray: any) => [...prevArray, data])
      })
    }
  }, [])

  useEffect(() => {
    dispatch(handle_variables({flowersReport: data}))
  }, [data])

  if (state?.basketUpdatedefaultValue?.status == 1) {
    return (
      <>
        <Container className='my-5'>
          <Table
            columns={columns}
            dataSource={tableData.length > 0 ? tableData : []}
            pagination={false}
            scroll={{x: true}}
            style={{fontFamily: 'IRANSans'}}
          />
        </Container>
      </>
    )
  }

  if (state?.basketUpdatedefaultValue?.status != 1 && !farmerUser) {
    return (
      <>
        <Container className='my-5'>
          <Button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              const newObject = {title: '', amount: 0, flowers: [], description: ''}
              setData((prev: any) => [...prev, newObject])
            }}
          >
            افزودن
          </Button>
          {data?.map((item: any, ind: number) => {
            const hidden = ind != 0 ? true : false
            return (
              <Row className='my-4' key={ind}>
                <Col md={4}>
                  <label hidden={hidden} className='form-label fw-bolder text-dark fs-6'>
                    عنوان
                  </label>
                  <input
                    type='text'
                    disabled={ind < 7}
                    onChange={(e) => {
                      setData((prevData) => {
                        const newData = [...prevData]
                        newData[ind] = {...newData[ind], title: e.target.value}
                        return newData
                      })
                    }}
                    defaultValue={item?.title}
                    className={'form-control form-control-lg form-control-solid'}
                    // {...formik.getFieldProps('flowerCount')}
                    placeholder='عنوان'
                  />
                </Col>
                <Col md={3}>
                  <label hidden={hidden} className='form-label fw-bolder text-dark fs-6'>
                    گل
                  </label>
                  {/* <input
                    type='text'
                    className={'form-control form-control-lg form-control-solid'}
                    // {...formik.getFieldProps('flowerCount')}
                    placeholder='گل'
                  /> */}
                  <DefaultDropDown
                    name='flower'
                    notCloseMenu={true}
                    //   value={selectedFlowers}
                    onChange={async (e: [{id: string | number; title: string}]) => {
                      setData((prevData) => {
                        const newData = [...prevData]
                        newData[ind] = {...newData[ind], flowers: e}
                        return newData
                      })
                    }}
                    type='default'
                    isMulti={true}
                    placeholder='گل'
                    options={flowers}
                    // defaultValue={
                    //   edit && state.sellRequestUpdatedefaultValue?.flower
                    //     ? state.sellRequestUpdatedefaultValue?.flower
                    //     : undefined
                    // }
                  />
                </Col>
                <Col md={1}>
                  <label hidden={hidden} className='form-label fw-bolder text-dark fs-6'>
                    تعداد
                  </label>
                  <input
                    style={{direction: 'rtl'}}
                    type='number'
                    className={'form-control form-control-lg form-control-solid'}
                    // {...formik.getFieldProps('flowerCount')}
                    onChange={(e) => {
                      setData((prevData) => {
                        const newData = [...prevData]
                        newData[ind] = {...newData[ind], amount: +e.target.value}
                        return newData
                      })
                    }}
                    placeholder='تعداد'
                  />
                </Col>
                <Col md={4}>
                  <label hidden={hidden} className='form-label fw-bolder text-dark fs-6'>
                    توضیحات
                  </label>
                  <input
                    type='text'
                    onChange={(e) => {
                      setData((prevData) => {
                        const newData = [...prevData]
                        newData[ind] = {...newData[ind], description: e.target.value}
                        return newData
                      })
                    }}
                    className={'form-control form-control-lg form-control-solid'}
                    // {...formik.getFieldProps('flowerCount')}
                    placeholder='توضیحات'
                  />
                </Col>
              </Row>
            )
          })}
        </Container>
      </>
    )
  }

  return <></>
}

export default SellReport
