import {FC, useEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {addSellRequest} from '../../../../typescript/interface'
import {useDispatch, useSelector} from 'react-redux'
import {
  getSellManagements,
  getSellRequests,
  handle_variables,
  sellState,
  setSellRequest,
  updateSellManagement,
  updateSellRequest,
} from '../../redux/sellSlice'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {authState} from '../../../auth/redux/AuthSlice'
import {flowerState, getOrganizationFlower} from '../../../flower/redux/flowerSlice'
import {cooperationState, getCoopRequest} from '../../../partners/Redux/cooperationSlice'
import {stringPrice} from '../../../../functions/stringPrice'
import {BeatLoader, GridLoader} from 'react-spinners'
import {toast} from 'react-toastify'
import { getGrades, gradeState } from '../../../grade/redux/gradeSlice'

var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  fixNumbers = function (str: any) {
    if (typeof str === 'string') {
      for (var i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
      }
    }
    return str
  }

const GradeModal = ({edit = false, defaultValue}: {edit?: boolean; defaultValue?: any}) => {
  const state = useSelector(sellState)
  const gradeS = useSelector(gradeState)

  const dispatch = useDispatch()


  useEffect(()=>{
    dispatch(getGrades({page: 1, pageSize: 100}))
  },[])

  const Schema = Yup.object().shape({
    price: Yup.string(),
  })

  const initialValues = {
    grade: defaultValue?.grade ? defaultValue?.grade : undefined,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values) => {
      dispatch(handle_variables({changeGradeLoading: true}))

      const data = {
        gradeId: values.grade,
      }
      const id = defaultValue.id

      try {
        await dispatch(updateSellManagement({id, data}))
        dispatch(getSellManagements({page: 1, pageSize: 10}))
      } catch (err) {
        throw err
      }
    },
  })

  return (
    <>
      <Modal
        size='lg'
        show={state?.changeGradeModalOpen}
        onHide={() => dispatch(handle_variables({changeGradeModalOpen: false}))}
      >
        <Modal.Header>
          <Modal.Title>تغییر قیمت</Modal.Title>
        </Modal.Header>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body>
            <>
              <Row>
                <Col lg={12} className='mt-2'>
                  <label className='form-label fw-bolder text-dark fs-6'>درجه گل</label>
                  <DefaultDropDown
                    name='flower'
                    onChange={(e: any) => {
                      formik.setFieldValue('grade', e.id)
                    }}
                    type='default'
                    placeholder='درجه گل'
                    options={gradeS.grades}
                    defaultValue={defaultValue?.grade ? defaultValue?.grade : undefined}
                  />
                </Col>
              </Row>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(handle_variables({changeGradeModalOpen: false}))
              }}
              variant='secondary'
            >
              انصراف
            </Button>
            <Button type={state?.changeGradeLoading ? 'button' : 'submit'} variant='primary'>
              {state?.changeGradeLoading ? <BeatLoader size={3} color='white' /> : ' ثبت'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default GradeModal
