import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import RegisterVerification from './components/registerVerification/RegisterVerification'



const UserManagmentPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/user-managment/register-verification'>
          <RegisterVerification />
        </Route>
      

      </Switch>
    </>
  )
}

export default UserManagmentPage
