import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ProfileHeader} from '../profile/ProfileHeader'
import Percentage from './components/percentageSell/Percentage'
import SellRegistration from './components/percentageSell/SaleRegistration'
import SellManagment from './components/percentageSell/SellManagment'
import SellRequestDetails from './components/percentageSell/SellRequestDetails'
import SaleFlowerHistory from './components/percentageSell/SaleFlowerHistory'
import Orders from './components/percentageSell/Orders'
import OrderDetails from './components/percentageSell/OrderDetails'
import AllSaleHstory from './components/percentageSell/AllHistorySale'
import SaleHistoryDashborad from './components/percentageSell/SaleHistotyDashboard'
// import {Overview} from './components/Overview'
// import {Projects} from './components/Projects'
// import {Campaigns} from './components/Campaigns'
// import {Documents} from './components/Documents'
// import {Connections} from './components/Connections'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/sell/precentage',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SellPage: React.FC = () => {
  return (
    <>
      {/* <ProfileHeader /> */}
      <Switch>
        <Route path='/sell/precentage/request'>
          {/* <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle> */}
          <Percentage />
        </Route>
        <Route path='/sell/precentage/manegment'>
          <SellManagment />
        </Route>
        <Route path='/sell/precentage/order'>
          <Orders />
        </Route>
        <Route path='/sell/precentage/all-sale-history'>
          <AllSaleHstory />
        </Route>
        <Route path='/sell/precentage/order-details/:id'>
          <OrderDetails />
        </Route>
        <Route path='/sell/precentage/sale-history-dashboard'>
          <SaleHistoryDashborad />
        </Route>
        <Route path='/sell/precentage/request-details/:id'>
          <SellRequestDetails />
        </Route>
        <Route path='/sell/sales-registration'>
          <SellRegistration />
        </Route>
        <Route path='/sell/sale-history/:id'>
          <SaleFlowerHistory />
        </Route>

        <Redirect from='/crafted/pages/profile' exact={true} to='/crafted/pages/profile/overview' />
        <Redirect to='/crafted/pages/profile/overview' />
      </Switch>
    </>
  )
}

export default SellPage
