import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import PercentageSellModal from './OrganizationFlowerModal'
import {useDispatch, useSelector} from 'react-redux'
import {
  deleteFlower,
  deleteOrganizationFlower,
  flowerState,
  getFlower,
  getFlowerUpdateData,
  getOrganizationFlower,
  handle_variables,
} from '../../redux/flowerSlice'
import {handle_variables as main_handle_variables} from '../../../../redux/mainSlice'
import FlowerDefinesModal from './OrganizationFlowerModal'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'
import {GridLoader} from 'react-spinners'
import {toast} from 'react-toastify'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import {authState} from '../../../auth/redux/AuthSlice'
import OrganizationFlowerModal from './OrganizationFlowerModal'
import ReactToPrint, {useReactToPrint} from 'react-to-print'
import QrPrint from './QrPrint'
import SelectExhibitorModal from './SelectExhibitorModal'

const OrganizationFlower = () => {
  const dispatch = useDispatch()


  const state = useSelector(flowerState)
  const authS = useSelector(authState)

  const [editMode, setEditMode] = useState(false)
  const [deleteComponentShow, setDeleteComponentShow] = useState(false)
  const [removeItemId, setRemoveItemId] = useState(0)
  const [org, setOrg] = useState({})
  const [flower, setFlower] = useState({})



  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
      organizationId: authS.user.organizationId,
    }
    dispatch(getOrganizationFlower(data))
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        organizationFlowerPagination: {...state.organizationFlowerPagination, page: currentPage},
      })
    )
    dispatch(
      getOrganizationFlower({
        page: currentPage,
        pageSize: state.organizationFlowerPagination.limit,
        organizationId: authS.user.organizationId,
      })
    )
  }

  const closeDeleteComponent = () => {
    setDeleteComponentShow(false)
  }

  const removeItem = () => {
    if (removeItemId > 0) {
      dispatch(deleteOrganizationFlower(removeItemId))
      setDeleteComponentShow(false)
      setTimeout(() => {
        dispatch(
          getOrganizationFlower({
            page: state.flowerPagination.page,
            pageSize: state.flowerPagination.limit,
            organizationId: authS.user.organizationId,
          })
        )
      }, 1000)
    } else {
      toast.error('خطای سیستمی')
    }
  }

  return (
    <>
      {state.addOrganizationFlowerModalOpen ? <OrganizationFlowerModal edit={editMode} /> : null}

      {state.selectExhibitorModalOpen ? <SelectExhibitorModal flower={flower} org={org} /> : null}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <Button
          onClick={() => {
            dispatch(handle_variables({addOrganizationFlowerModalOpen: true}))
            setEditMode(false)
          }}
          className='percentage-sell-btn'
        >
          افزودن گل جدید
        </Button>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>لیست گل های من</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
        </div>

        <div className='card-body py-3'>
          {/* begin::Table container */}
          {state.organizationFlowerTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              {state.organizationFlowers.length < 1 ? (
                'موردی یافت نشد'
              ) : (
                <>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th> */}
                        <th className='min-w-140px'>شماره گل</th>
                        <th className='min-w-140px'>نام گل</th>
                        <th className='min-w-140px'></th>
                        <th className='min-w-140px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.organizationFlowers.map((item: any, ind: number) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {ind + 1}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item.title}
                              </a>
                              {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Code: PH</span> */}
                            </td>

                            <td className='text-end'>
                              <div
                                onClick={() => {
                                  setFlower(item)
                                  setOrg(item?.organizations[0])
                                }}
                              >
                              
                                <Button onClick={()=>{
                                  dispatch(handle_variables({selectExhibitorModalOpen:true}));
                                }}
                                 style={{padding: '8px 25px'}}>دریافت qr</Button>
                                <button
                                  onClick={(e) => {
                                    setDeleteComponentShow(true)
                                    setRemoveItemId(item.id)
                                  }}
                                  className='btn ms-2 btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </>
              )}
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
          <div className='d-flex justify-content-center mt-10'>
            <Pagination
              pageSize={state?.organizationFlowerPagination.limit}
              onChange={(e) => paginationOnChange(e)}
              current={state?.organizationFlowerPagination.page}
              total={state?.organizationFlowerPagination.total}
            />
            <Select
              defaultValue={state.organizationFlowerPagination.limit}
              style={{width: 120, marginRight: '15px', height: '28px'}}
              onChange={(e) => {
                dispatch(
                  handle_variables({
                    organizationFlowerPagination: {...state.organizationFlowerPagination, limit: e},
                  })
                )
                dispatch(
                  getFlower({
                    page: 1,
                    pageSize: e,
                  })
                )
              }}
              options={[
                {value: 10, label: 10},
                {value: 20, label: 20},
                {value: 50, label: 50},
                // { value: 'disabled', label: 'Disabled', disabled: true },
              ]}
            />
          </div>
        </div>

        {/* begin::Body */}
      </div>

      <DeleteConfirmModal
        text={'آیا از پاک کردن این گل مطمعن هستید؟'}
        show={deleteComponentShow}
        close={() => closeDeleteComponent()}
        onDelete={() => removeItem()}
      />
    </>
  )
}

export default OrganizationFlower
