import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import CoomperationRequest from './components/CoomperationRequest'
import CooperationList from './components/CooperationList'

const CooprationPage: React.FC = () => {
  return (
    <>
      {/* <ProfileHeader /> */}
      <Switch>
        <Route path='/cooperation/list'>
          {/* <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle> */}
          <CooperationList />
        </Route>
        <Route path='/cooperation/request'>
          <CoomperationRequest />
        </Route>

        <Redirect from='/crafted/pages/profile' exact={true} to='/crafted/pages/profile/overview' />
        <Redirect to='/crafted/pages/profile/overview' />
      </Switch>
    </>
  )
}

export default CooprationPage
