import axios from 'axios'
import {API_URL} from '../../config/config'

export const GET_PROVINCE = 'province'
export const GET_TOWNSHIP = 'township'

export function get_province() {
  return axios.get(API_URL + GET_PROVINCE)
}

export function get_township(id: number | string) {
  return axios.get(API_URL + GET_TOWNSHIP + '?provinceId=' + id)
}
