import {QRCode} from 'antd'

const QrPrint = ({
  flower,
  org,
  exhibitor,
}: {
  flower: any
  org: any
  exhibitor?: any[]
}) => {
  const data = {
    flower,
    org,
  }



  return (
    <div className='hide-on-screen d-flex'>
      <div className='container'>
        <div className='row'>
          {exhibitor?.map((item: any) => {
            return (
              <div className='col-12 mt-5 d-flex justify-content-center' style={{textAlign:"center"}}>
                <QRCode
                  // size={200}
                  // style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                  value={JSON.stringify({
                    flower: flower?.id,
                    org : org?.id,
                    exhibitor: item.id,
                  })}
                />
                <div className='ms-8 d-flex flex-column justify-content-center align-items-center'>
                  <span className='my-2 fs-1 fw-bold'>گل {flower?.title}</span>
                  <span className='my-2 fs-1 fw-bold'>گلخانه {org?.title}</span>
                  <span className='my-2 fs-1 fw-bold'>غرفه {item?.title}</span>
                </div>

              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default QrPrint
