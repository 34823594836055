import {API_URL} from '../../../../config/config'
import {SecureCall} from '../../../functions/secaurCall'

export const ORGANIZATION = 'organization'
export const COOPERATION = 'cooperation'

export function get_organizations(data: any) {
  const params = new URLSearchParams()

  if (data?.page) {
    // params = params + `page=${page}`
    params.append('page', data?.page.toString())
  }
  if (data?.pageSize) {
    params.append('limit', data?.pageSize.toString())
  }
  if (typeof data?.brandName == 'string') {
    params.append('title', data?.brandName)
  }

  if (typeof data?.province == 'number') {
    params.append('provinceId', data?.province)
  }

  if (typeof data?.township == 'number') {
    params.append('townshipId', data?.township)
  }

  return SecureCall.get(API_URL + ORGANIZATION + '?' + params)
}

export function get_coomperation_request(page: number, pageSize: number) {
  return SecureCall.get(API_URL + COOPERATION + `?page=${page}&limit=${pageSize}`)
}

export function set_cooperation_request(data: any) {
  return SecureCall.post(API_URL + COOPERATION, data)
}

export function delete_cooperation_request(id: number) {
  return SecureCall.delete(API_URL + COOPERATION + `/${id}`)
}

export function update_cooperation_request(id: number, data: any) {
  return SecureCall.update(API_URL + COOPERATION + `/${id}`, data)
}

// export function update_flower(id: number, data: any) {
//   return SecureCall.update(API_URL + FLOWER + `/${id}`, data)
// }

// export function get_organization_flowers(page: number, pageSize: number, organizationId: number) {
//   return SecureCall.get(
//     API_URL + FLOWER + `?page=${page}&limit=${pageSize}&organizationId=${organizationId}`
//   )
// }
