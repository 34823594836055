import React, {useState, useEffect} from 'react'

const QRScanner = ({scanQr}) => {
  const [scannedCode, setScannedCode] = useState('')
  const [scanTimeout, setScanTimeout] = useState(null)

  const keyMap = {
    Digit0: '0',
    Digit1: '1',
    Digit2: '2',
    Digit3: '3',
    Digit4: '4',
    Digit5: '5',
    Digit6: '6',
    Digit7: '7',
    Digit8: '8',
    Digit9: '9',
    KeyA: 'a',
    KeyB: 'b',
    KeyC: 'c',
    KeyD: 'd',
    KeyE: 'e',
    KeyF: 'f',
    KeyG: 'g',
    KeyH: 'h',
    KeyI: 'i',
    KeyJ: 'j',
    KeyK: 'k',
    KeyL: 'l',
    KeyM: 'm',
    KeyN: 'n',
    KeyO: 'o',
    KeyP: 'p',
    KeyQ: 'q',
    KeyR: 'r',
    KeyS: 's',
    KeyT: 't',
    KeyU: 'u',
    KeyV: 'v',
    KeyW: 'w',
    KeyX: 'x',
    KeyY: 'y',
    KeyZ: 'z',
    BracketLeft: '{',
    BracketRight: '}',
    Comma: ',',
    Quote: '"',
    Semicolon: ':', // Use Semicolon for colon in some keyboard layouts
    KeyQ: 'Q',
    KeyW: 'W',
    KeyE: 'E',
    KeyR: 'R',
    KeyT: 'T',
    KeyY: 'Y',
    KeyU: 'U',
    KeyI: 'I',
    KeyO: 'O',
    KeyP: 'P',
    KeyA: 'A',
    KeyS: 'S',
    KeyD: 'D',
    KeyF: 'F',
    KeyG: 'G',
    KeyH: 'H',
    KeyJ: 'J',
    KeyK: 'K',
    KeyL: 'L',
    KeyZ: 'Z',
    KeyX: 'X',
    KeyC: 'C',
    KeyV: 'V',
    KeyB: 'B',
    KeyN: 'N',
    KeyM: 'M',
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      // Handle both regular characters and special characters based on event.code
      if (keyMap[event.code] !== undefined) {
        setScannedCode((prev) => prev + keyMap[event.code])

        // Clear previous timeout
        if (scanTimeout) {
          clearTimeout(scanTimeout)
        }

        // Set a new timeout to clear the scanned code after a period of inactivity
        setScanTimeout(setTimeout(() => setScannedCode(''), 500))
      } else {
        // Fallback for special characters like colon
        if (event.key === ':') {
          setScannedCode((prev) => prev + ':')
        }
      }
    }

    const handleKeyUp = (event) => {
      if (event.code === 'Enter') {
        console.log('Scanned QR Code:', scannedCode)
        try {
          const jsonData = JSON.parse(scannedCode)
          scanQr(jsonData)
          // console.log('Parsed JSON:', jsonData)
          // You can process the scanned code here
        } catch (e) {
          console.error('Invalid JSON:', scannedCode)
        }
        setScannedCode('')
      }
    }

    window.addEventListener('keydown', handleKeyPress)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [scannedCode, scanTimeout])

  return (
    <div>
      {/* <h2>Scan a QR Code</h2>
      <p>Scanned Code: {scannedCode}</p> */}
    </div>
  )
}

export default QRScanner
