import {FC, useEffect, useLayoutEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import {flowerDefineDefault} from '../../../../typescript/interface'
import type {UploadProps} from 'antd'
import {Button as AntdBtn, Image, message, Upload} from 'antd'
import {toast, ToastContainer} from 'react-toastify'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {BeatLoader, GridLoader} from 'react-spinners'
import sellSlice, {handle_variables, sellState, updateCart} from '../../redux/sellSlice'
import {accountState, getBuyers} from '../../../accounts/Redux/AccountSlice'
import {inits} from '../../../wizards/components/CreateAccountWizardHelper'

const OrderEditModal = () => {
  const state = useSelector(sellState)
  const accountS = useSelector(accountState)

  const [buyersExist, setBuyersExist] = useState(false)
  const [buyer, setBuyer] = useState([])
  const dispatch = useDispatch()

  const cartDetail = state?.cartDetails

  // const Schema = Yup.object().shape({
  //   flowerName: Yup.string().required('نام گل نمی تواند خالی باشد'),
  // })

  let initialValues = {
    cash: cartDetail?.cash,
    buyerUserId: cartDetail?.buyerUserId,
    saleHistories: cartDetail?.saleHistories,
  }

  useEffect(() => {
    //get buyers
    dispatch(getBuyers({page: 1, pageSize: 500}))
  }, [])

  if (!buyersExist && accountS?.buyers?.length > 0) {
    const mappedArray = accountS?.buyers?.map((item: any) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    }))
    setBuyer(mappedArray)
    setBuyersExist(true)
  }

  // console.log('stateee', state)
  // console.log('cartDetail', cartDetail)
  console.log('initialValues', initialValues)

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: Schema,
    onSubmit: async (values) => {
      console.log('herrere', values)
      try {
        dispatch(handle_variables({orderEditSubmitLoading: true}))
        const data = {
          id: cartDetail?.id,
          data: values,
        }
        await dispatch(updateCart(data))

        toast.success('تغییر با موفقیت انجام شد')
        dispatch(handle_variables({orderEditSubmitLoading: false, orderEditModalOpen: false}))
      } catch (error) {
        dispatch(handle_variables({orderEditSubmitLoading: false}))
        toast.success('خطای غیر منتظره')
      }
      //   console.log('flowerImage', flowerImage)
      //   dispatch(handle_variables({flowerAddLoading: true}))

      //Condition for create and update
      //   if (edit) {
      //     //update
      //     const data = {
      //       title: values.flowerName,
      //     }
      //     const id = state.flowerUpdatedefaultValue.id
      //     const info = {
      //       id,
      //       data,
      //     }
      //     dispatch(updateFlower(info))
      //   } else {
      //     //create

      //     const data = {
      //       title: values.flowerName,
      //       image: flowerImage,
      //     }

      //     dispatch(setFlower(data))
      //   }
      //   setTimeout(() => {
      //     dispatch(
      //       getFlower({page: state.flowerPagination.page, pageSize: state.flowerPagination.limit})
      //     )
      //   }, 1000)
    },
  })

  return (
    <>
      <Modal
        size='lg'
        show={state?.orderEditModalOpen}
        onHide={() => dispatch(handle_variables({orderEditModalOpen: false}))}
      >
        <Modal.Header>
          <Modal.Title>ویرایش سفارش</Modal.Title>
        </Modal.Header>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body>
            {state.cartDetailsLoading ? (
              <div className='d-flex justify-content-center'>
                {' '}
                <GridLoader size={5} color={'gray'} />
              </div>
            ) : (
              <>
                {cartDetail?.saleHistories?.length > 0 && (
                  <>
                    {cartDetail?.saleHistories?.map((item: any, ind: number) => {
                      return (
                        <Row className='my-3'>
                          <Col md={6}>
                            <label className='form-label fw-bolder text-dark fs-6'>نام گل</label>

                            <input
                              className={'form-control form-control-lg form-control-solid'}
                              {...formik.getFieldProps(
                                `saleHistories[${ind}].saleManagement.flower.title`
                              )}
                              disabled
                              placeholder='نام گل'
                            />
                          </Col>
                          <Col md={3}>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              تعداد فروش
                            </label>

                            <input
                              type='number'
                              className={'form-control form-control-lg form-control-solid'}
                              // defaultValue={item?.amount}
                              {...formik.getFieldProps(`saleHistories[${ind}].amount`)}
                              placeholder='تعداد فروش'
                            />
                          </Col>
                          <Col md={3}>
                            <label className='form-label fw-bolder text-dark fs-6'>قیمت فروش</label>
                            <input
                              type='number'
                              className={'form-control form-control-lg form-control-solid'}
                              {...formik.getFieldProps(`saleHistories[${ind}].finalPrice`)}
                              placeholder='قیمت فروش'
                            />
                          </Col>
                        </Row>
                      )
                    })}
                    <hr />

                    <Row>
                      <Col md={6} className='my-3'>
                        <label className='form-label fw-bolder text-dark fs-6'>نام خریدار</label>
                        <DefaultDropDown
                          name='flower'
                          onChange={(e: any) => {
                            formik.setFieldValue('buyerUserId', e.value)
                          }}
                          defaultValue={{
                            value: cartDetail?.buyerUser?.id,
                            title:
                              cartDetail?.buyerUser?.firstName +
                              ' ' +
                              cartDetail?.buyerUser?.lastName,
                          }}
                          type='default'
                          placeholder='نام خریدار'
                          options={buyer}
                        />
                      </Col>
                      <Col md={6} className='my-3'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          مبلغ پرداختی نقدی
                        </label>
                        <input
                          type='number'
                          className={'form-control form-control-lg form-control-solid'}
                          {...formik.getFieldProps('cash')}
                          // defaultValue={initialValues?.cash}
                          placeholder='مبلغ پرداختی نقدی'
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(handle_variables({orderEditModalOpen: false}))
              }}
              variant='secondary'
            >
              انصراف
            </Button>
            <Button
              disabled={state?.orderEditSubmitLoading ? true : false}
              type='submit'
              variant='primary'
            >
              {state?.orderEditSubmitLoading ? (
                <div>
                  <BeatLoader size={6} color={'white'} />
                </div>
              ) : (
                'ثبت'
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default OrderEditModal
