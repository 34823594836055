import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {get_province, get_township} from './mainApi'

const initialState = {
  deleteModalOpen: false,
  provinces: [],
  townships: [],
  captchaToken : null ,
  refreshCaptchaStatus : false
}

export const getProvince = createAsyncThunk('auth/getProvince', async () => {
  try {
    const response = await get_province()
    return response.data
  } catch (err) {
    throw err
  }
})

export const getTownship = createAsyncThunk('auth/getTownship', async (id: number) => {
  try {
    const response = await get_township(id)
    return response.data
  } catch (err) {
    throw err
  }
})

export const refreshCaptcha = createAsyncThunk('auth/refreshCaptcha', async () => {
  try {
    return 'true'
  } catch (err) {
    throw err
  }
})


const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProvince.fulfilled, (state, action) => {
        if (action.payload) {
          state.provinces = action.payload.data
        }
      })
      .addCase(getTownship.fulfilled, (state, action) => {
        if (action.payload) {
          state.townships = action.payload.data
        }
      })
      .addCase(refreshCaptcha.fulfilled, (state, action) => {
        state.refreshCaptchaStatus= !state.refreshCaptchaStatus
      })
  },
})

export const {handle_variables} = mainSlice.actions

export const mainState = (state: any) => state.main

export default mainSlice.reducer
