import {FC, useEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {addSellRequest} from '../../../../typescript/interface'
import {useDispatch, useSelector} from 'react-redux'

import {useFormik} from 'formik'
import * as Yup from 'yup'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {authState} from '../../../auth/redux/AuthSlice'
import {flowerState, getOrganizationFlower} from '../../../flower/redux/flowerSlice'
import {cooperationState, getCoopRequest} from '../../../partners/Redux/cooperationSlice'
import {stringPrice} from '../../../../functions/stringPrice'
import {BeatLoader, GridLoader} from 'react-spinners'
import {toast} from 'react-toastify'
import {
  financState,
  getPayments,
  handle_variables,
  setPayment,
} from '../../redux/financSlice'
import DefaultDatePicker from '../../../../components/DefaultDatePicker'
import {accountState, getBuyers} from '../../../accounts/Redux/AccountSlice'

var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  fixNumbers = function (str: any) {
    if (typeof str === 'string') {
      for (var i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
      }
    }
    return str
  }

const PaymentRegistrationModal = ({
  edit = false,
  defaultValue,
}: {
  edit?: boolean
  defaultValue?: any
}) => {
  const state = useSelector(financState)
  const accountS = useSelector(accountState)

  const dispatch = useDispatch()

  const [buyer, setBuyer] = useState([])
  const [buyersExist, setBuyersExist] = useState(false)

  const Schema = Yup.object().shape({
    amount: Yup.string().required('مبلغ پرداخت نمی نواند خالی باشد'),
    buyer: Yup.string().required('  پرداخت کننده نمی نواند خالی باشد'),

  })

  const initialValues = {
    amount: '',
    buyer: '',

  }

  useEffect(() => {
    //get buyers
    dispatch(getBuyers({page: 1, pageSize: 500}))


  }, [])

  if (!buyersExist && accountS?.buyers?.length > 0) {
    const mappedArray = accountS?.buyers?.map((item: any) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    }))
    setBuyer(mappedArray)
    setBuyersExist(true)
  }


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values) => {
      dispatch(handle_variables({paymentSetLoading: true}))

      try {
        const data = {
          amount: values.amount,
          buyerUserId: values.buyer,
          date: new Date().toLocaleDateString()
        }

        await dispatch(setPayment(data))
        dispatch(handle_variables({addPaymentModalOpen: false}))
        dispatch(
          getPayments({page: state.paymentPagination.page, pageSize: state.paymentPagination.limit})
        )
      } catch (err) {
        throw err
      }
    },
  })

  return (
    <>
      <Modal
        size='lg'
        show={state?.addPaymentModalOpen}
        onHide={() => dispatch(handle_variables({addPaymentModalOpen: false}))}
      >
        <Modal.Header>
          <Modal.Title>ثبت درخواست نقد</Modal.Title>
        </Modal.Header>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body>
            <>
              
              <Row>
                <Col md={6} className='mt-4'>
                  <label className='form-label fw-bolder text-dark fs-6'>مبلغ پرداخت</label>
                  <input
                    type='number'
                    // value={promissoryNotes[index].promissoryNoteValue}
                    style={{direction: 'rtl'}}
                    className={'form-control form-control-lg form-control-solid no-spinners'}
                    {...formik.getFieldProps(`amount`)}
                    placeholder='مبلغ پرداخت'
                  />
                  <div style={{color: 'green'}}>
                    {stringPrice({
                      data: formik.values.amount,
                    })}
                  </div>
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='invalid-error-text' role='alert'>
                          {formik.errors.amount}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>

                <Col md={6} className='mt-4'>
                  <label className='form-label fw-bolder text-dark fs-6'>پرداخت کننده</label>
                  <DefaultDropDown
                    name='buyer'
                    onChange={(e: any) => {
                      formik.setFieldValue('buyer', e.value)
                    }}
                    type='default'
                    placeholder='پرداخت کننده'
                    options={buyer}
                  />
                  {formik.touched.buyer && formik.errors.buyer && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='invalid-error-text' role='alert'>
                          {formik.errors.buyer}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
             
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(handle_variables({addPaymentModalOpen: false}))
              }}
              variant='secondary'
            >
              انصراف
            </Button>
            <Button type={state?.paymentSetLoading ? 'button' : 'submit'} variant='primary'>
              {state?.paymentSetLoading ? <BeatLoader size={3} color='white' /> : ' ثبت'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default PaymentRegistrationModal
