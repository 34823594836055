import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'

interface sendData {
  email: string
  password: string
}

interface CustomError extends AxiosResponse {
  response: {
    data: {
      message: string
    }
  }
}

const initialState = {
  authorization: false,
  info: null,
  flowerDefineModalOpen: false,
}

export const loginUser = createAsyncThunk('flower/login', async (data: sendData) => {
  // try {
  //   const response = await login_user(data);
  //   return response;
  // } catch (err) {
  //   const customError = err as CustomError;
  //   toast.error(customError?.response?.data?.message);
  //   throw err;
  // }
  return 'ali'
})

const flowerSlice = createSlice({
  name: 'flower',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log('aloooooo')
      })
      .addCase(loginUser.rejected, (state, action) => {
        console.log('errorr', action)
      })
  },
})

export const {handle_variables} = flowerSlice.actions

export const flowerState = (state: any) => state.flower

export default flowerSlice.reducer
