import {API_URL} from '../../../../config/config'
import {SecureCall} from '../../../functions/secaurCall'

export const FLOWER = 'flower'
export const ORGANIZATION_FLOWER = 'organizationFlower'

export function get_flowers(page: number, pageSize: number) {
  return SecureCall.get(API_URL + FLOWER + `?page=${page}&limit=${pageSize}`)
}

export function set_flower(data: any) {
  return SecureCall.post(API_URL + FLOWER, data)
}

export function delete_flower(id: number) {
  return SecureCall.delete(API_URL + FLOWER + `/${id}`)
}

export function get_flower_update_data(id: number) {
  return SecureCall.get(API_URL + FLOWER + `/${id}`)
}

export function update_flower(id: number, data: any) {
  return SecureCall.update(API_URL + FLOWER + `/${id}`, data)
}

export function get_organization_flowers(page: number, pageSize: number,organizationId: number) {
  return SecureCall.get(API_URL + FLOWER + `?page=${page}&limit=${pageSize}&organizationId=${organizationId}`)
}

export function set_organization_flower(data: any) {
  return SecureCall.post(API_URL + ORGANIZATION_FLOWER, data)
}

export function delete_organization_flower(id: number) {
  return SecureCall.delete(API_URL + ORGANIZATION_FLOWER + `/${id}`)
}
