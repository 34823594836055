import {FC, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {flowerState, handle_variables} from '../redux/flowerSlice'
import {Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import {returnFlowerRequest} from '../../../typescript/interface'
import type {UploadProps} from 'antd'
import {Button as AntdBtn, message, Upload} from 'antd'
import {toast, ToastContainer} from 'react-toastify'
import DefaultDropDown from '../../../components/DefaultDropDown'

const ReturnFlowerRequestModal = ({
  edit = false,
  defaultValue,
}: {
  edit?: boolean
  defaultValue?: returnFlowerRequest
}) => {
  const state = useSelector(flowerState)
  const dispatch = useDispatch()

  const [fileList, setFileList] = useState([])

  const fileUpload: UploadProps = {
    beforeUpload(file) {
      console.log('file', file)
      if (file.size > 1 * 1024 * 1024) {
        toast.error('تصویر انتخاب شده باید کمتر از 1 مگابایت باشد ')
      }
      return false
      if (fileList.length > 0) {
        toast.error('نمی توانید بیشتر از یک عکس انتخاب کنید')
        // const index = fileList.indexOf(file)
        const newFileList = fileList.slice()
        // newFileList.splice(index, 1)
        setFileList(newFileList)
        // setFileList(file)
        return false
      }
    },
  }

  const Schema = Yup.object().shape({
    flower: Yup.string().required('نام گل نمی تواند خالی باشد'),
    discription: Yup.string().required('نام گل نمی تواند خالی باشد'),
  })

  const initialValues = {
    flower: edit && defaultValue?.flower ? defaultValue?.flower : '',
    discription: edit && defaultValue?.discription ? defaultValue?.discription : '',
  }

  const onRemoveImage = () => {
    // Your custom logic when the delete icon is clicked
    // setFlowerImage(null)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: (values) => {
      console.log('herrere', values)
    },
  })

  return (
    <Modal
      size='lg'
      show={state?.flowerDefineModalOpen}
      onHide={() => dispatch(handle_variables({flowerDefineModalOpen: false}))}
    >
      <Modal.Header>
        <Modal.Title>درخواست مرجوعی</Modal.Title>
      </Modal.Header>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <Modal.Body>
          <Row>
            <Col md={6}>
              <label className='form-label fw-bolder text-dark fs-6'>گل</label>
              <DefaultDropDown
                name='flower'
                onChange={(e: {id: string | number; title: string}) => {
                  formik.setFieldValue('flower', e.id)
                }}
                type='default'
                placeholder='گل'
                // options={options}
              />
              {formik.touched.flower && formik.errors.flower && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='invalid-error-text' role='alert'>
                      {formik.errors.flower}
                    </span>
                  </div>
                </div>
              )}
            </Col>
            <Col lg={6}>
              <label className='form-label fw-bolder text-dark fs-6'>آپلود عکس</label>
              <Upload
                accept='image/png, image/jpeg'
                maxCount={1}
                className='upload-flower-image'
                {...fileUpload}
                onRemove={() => onRemoveImage()}
              >
                <AntdBtn
                  style={{
                    width: '100%',
                    color: '#5e6278',
                    backgroundColor: '#f5f8fa',
                    borderColor: '#f5f8fa',
                    textAlign: 'start',
                  }}
                >
                  آپلود عکس
                </AntdBtn>
              </Upload>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <label className='form-label fw-bolder text-dark fs-6'>توضیحات</label>
              <textarea
                className={'form-control form-control-lg form-control-solid'}
                {...formik.getFieldProps('discription')}
                placeholder='توضیحات'
              />
              {formik.touched.discription && formik.errors.discription && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='invalid-error-text' role='alert'>
                      {formik.errors.discription}
                    </span>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(handle_variables({flowerDefineModalOpen: false}))
            }}
            variant='secondary'
          >
            انصراف
          </Button>
          <Button type='submit' variant='primary'>
            ثبت
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ReturnFlowerRequestModal
