/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {Link, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {GridLoader} from 'react-spinners'
import AsyncSelect from 'react-select/async'
import DefaultDropDown from '../../../components/DefaultDropDown'
import {authState, handle_variables, register} from '../redux/AuthSlice'
import {SecureCall} from '../../../functions/secaurCall'
import {getProvince, getTownship, mainState} from '../../../redux/mainSlice'
import { converPersianNumberToEnglish } from '../../../functions/convertPersianNumberToEnglish'

const initialValues = {
  firstname: '',
  lastname: '',
  brandName: '',
  userType: '',
  province: '',
  township: '',
  phone: '',
  email: '',
  password: '',
  confirmpassword: '',
  acceptTerms: false,
}

type SelectOption = {
  value: number
  label: string
}

const userTypeOptions: SelectOption[] = [
  {value: 1, label: 'مزرعه دار'},
  {value: 2, label: 'غرفه دار'},
]

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none', // Remove the border
    backgroundColor: '#f5f8fa', // Set the background color
    boxShadow: state.isFocused ? null : null,
  }),
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'نام نمی تواند کمتر از 3 کاراکتر باشد')
    .max(30, 'نام نمی تواند بیشتر از 30 کاراکتر باشد')
    .required('نام نمی تواند خالی باشد'),
  email: Yup.string()
    .email('فرمت ایمیل صحیح نمی باشد')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('ایمیل نمی تواند خالی باشد'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('نام خانوادگی نمی تواند خالی باشد'),
  brandName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('نام برند نمی تواند خالی باشد'),
  userType: Yup.string().required('نوع کاربری نمی تواند خالی باشد'),
  province: Yup.string().required('استان نمی تواند خالی باشد'),
  township: Yup.string().required('شهرستان نمی تواند خالی باشد'),
  phone: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('شماره همراه نمی تواند خالی باشد'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('رمز عبور نمی تواند خالی باشد'),
  confirmpassword: Yup.string()
    .required('رمز عبور تکراری نمی تواند خالی باشد')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'رمز عبور تکراری با رمز عبور یکسان نمی باشد'),
    }),
  acceptTerms: Yup.bool().required('باید شرایط و قوانین را قبول کنید'),
})

export function Registration() {
  // const [loading, setLoading] = useState(false)
  const [province, setProvince] = useState({id: 0, title: ''})
  const dispatch = useDispatch()

  const history = useHistory()

  const state = useSelector(mainState)

  useEffect(() => {
    dispatch(getProvince())
  }, [])

  console.log('state', state)
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      dispatch(handle_variables({registerLoading: true}))
      console.log('valuesssss', values)

      const user = {
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        phone: values.phone,
        password: values.password,
        confirmPassword: values.confirmpassword,
        provinceId: values.province,
        townshipId: values.township,
      }

      const organization = {
        organizationTypeId: Number(values.userType),
        title: values.brandName,
        provinceId: values.province,
        townshipId: values.township,
      }

      const data = {
        user,
        organization,
      }

      console.log('dattatatatatttatatattata', data)

      try {
        await dispatch(register(data))
        history.push('/auth/login')
      } catch (error) {}
    },
  })
  console.log('formmmmm', formik)
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>ثبت نام</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4 mt-7'>
          قبلا ثبت نام کرده اید؟
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            دریافت فراموشی رمز عبور
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button> */}
      {/* end::Action */}

      {/* <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div> */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6 ms-1'>نام</label>
          <input
            placeholder='نام'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={'form-control form-control-lg form-control-solid'}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='invalid-error-text' role='alert'>
                  {formik.errors.firstname}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0 ms-1'>نام خانوادگی</label>
            <input
              placeholder='نام خانوادگی'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={'form-control form-control-lg form-control-solid'}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='invalid-error-text' role='alert'>
                    {formik.errors.lastname}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>ایمیل</label>
        <input
          style={{direction: 'rtl'}}
          placeholder='ایمیل'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={'form-control form-control-lg form-control-solid'}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='invalid-error-text' role='alert'>
                {formik.errors.email}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Phone */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>شماره همراه</label>
        <input
          placeholder='شماره همراه'
          autoComplete='off'
          {...formik.getFieldProps(converPersianNumberToEnglish('phone'))}
          className={'form-control form-control-lg form-control-solid'}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='invalid-error-text' role='alert'>
                {formik.errors.phone}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group UserType */}

      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>نوع کاربری</label>
        <DefaultDropDown
          name='userType'
          type='default'
          placeholder='نوع کاربری'
          onChange={(e: {value: number; label: string}) => {
            formik.setFieldValue('userType', e.value)
          }}
          options={userTypeOptions}
        />
        {formik.touched.userType && formik.errors.userType && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='invalid-error-text' role='alert'>
                {formik.errors.userType}
              </span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Form group BrandName */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>نام برند</label>
        <input
          placeholder='نام برند'
          autoComplete='off'
          {...formik.getFieldProps('brandName')}
          className={'form-control form-control-lg form-control-solid'}
        />
        {formik.touched.brandName && formik.errors.brandName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='invalid-error-text' role='alert'>
                {formik.errors.brandName}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Province */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>استان</label>
        <DefaultDropDown
          name='province'
          onChange={(e: {id: number; title: string}) => {
            formik.setFieldValue('province', e.id)
            setProvince({id: e.id, title: e.title})
            dispatch(getTownship(e.id))
          }}
          type='default'
          placeholder='استان'
          options={state.provinces.length > 0 && state.provinces}
        />
        {formik.touched.province && formik.errors.province && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='invalid-error-text' role='alert'>
                {formik.errors.province}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group Province */}

      {/* begin::Form group township */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>شهرستان</label>
        <DefaultDropDown
          name='township'
          onChange={(e: {id: string | number; title: string}) => {
            formik.setFieldValue('township', e.id)
          }}
          type='default'
          disabled={province.title != '' ? false : true}
          placeholder='شهرستان'
          options={state.townships.length > 0 && state.townships}
        />
        {formik.touched.township && formik.errors.township && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='invalid-error-text' role='alert'>
                {formik.errors.township}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group township */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>رمز عبور</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='رمز عبور'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={'form-control form-control-lg form-control-solid'}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='invalid-error-text' role='alert'>
                    {formik.errors.password}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>تکرار رمز عبور</label>
        <input
          type='password'
          placeholder='تکرار رمز عبور'
          autoComplete='off'
          {...formik.getFieldProps('confirmpassword')}
          className={'form-control form-control-lg form-control-solid'}
        />
        {formik.touched.confirmpassword && formik.errors.confirmpassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='invalid-error-text' role='alert'>
                {formik.errors.confirmpassword}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            با تمام قوانین و شرایط موافقم
            {/* <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link> */}
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='invalid-error-text' role='alert'>
                  {formik.errors.acceptTerms}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!state.registerLoading && <span className='indicator-label'>ثبت</span>}
          {state.registerLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              <GridLoader size={4} color={'white'} />
              {/* Please wait...{' '} */}
              {/* <span className='spinner-border spinner-border-sm align-middle ms-2'></span> */}
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            بازگشت
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
