import {FC, useEffect, useState} from 'react'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'

import {useDispatch, useSelector} from 'react-redux'

import {GridLoader, HashLoader} from 'react-spinners'
import {toast} from 'react-toastify'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import {
  getRegisterVerifications,
  handle_variables,
  updateRegisterVerification,
  userManagmentState,
} from '../../redux/UserManagmentSlice'
import {KTSVG} from '../../../../../_metronic/helpers'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'
import {convertDate} from '../../../../functions/convertDate'

const RegisterVerification = () => {
  const dispatch = useDispatch()
  const state = useSelector(userManagmentState)

  const [deleteComponentShow, setDeleteComponentShow] = useState(false)
  const [removeItemId, setRemoveItemId] = useState(0)

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getRegisterVerifications(data))
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        registerVerificationPagination: {
          ...state.registerVerificationPagination,
          page: currentPage,
        },
      })
    )
    dispatch(
      getRegisterVerifications({
        page: currentPage,
        pageSize: state.registerVerificationPagination.limit,
      })
    )
  }

  const closeDeleteComponent = () => {
    setDeleteComponentShow(false)
  }

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>لیست درخواست ثبت نام ها</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
        </div>

        <div className='card-body py-3'>
          {/* begin::Table container */}
          {state.registerVerificationTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              {state.registerVerifications.length < 1 ? (
                'موردی یافت نشد'
              ) : (
                <>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-140px'>نام</th>
                        <th className='min-w-140px'>نام برند</th>
                        <th className='min-w-140px'>نوع کاربر</th>
                        <th className='min-w-140px'>شماره همراه</th>
                        <th className='min-w-140px'>استان</th>
                        <th className='min-w-140px'>شهرستان</th>
                        <th className='min-w-140px'>تاریخ درخواست</th>
                        <th className='min-w-140px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.registerVerifications.map((item: any, ind: number) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item?.users[0]?.firstName} {item?.users[0]?.lastName}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item.title}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item.organizationTypeId == 1 ? 'مزرعه دار' : 'غرفه دار'}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.users[0]?.phone}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.province?.title}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.township?.title}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {convertDate({date: item.createdAt, type: 1})}
                              </a>
                            </td>
                            <td>
                              {state?.registerVerificationsubmitLoading ? (
                                <HashLoader size={3} />
                              ) : (
                                <div>
                                  <button
                                    type='button'
                                    className='btn  btn-danger '
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    onClick={async () => {
                                      try {
                                        const data = {
                                          id: item.id,
                                          data: {
                                            organizationStatusId: 0,
                                          },
                                        }
                                        await dispatch(updateRegisterVerification(data))

                                        await dispatch(
                                          getRegisterVerifications({page: 1, pageSize: 10})
                                        )
                                      } catch (error) {}
                                    }}
                                  >
                                    رد
                                  </button>
                                  <button
                                    type='button'
                                    className='btn ms-2 btn-primary '
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    onClick={async () => {
                                      try {
                                        const data = {
                                          id: item.id,
                                          data: {
                                            organizationStatusId: 1,
                                          },
                                        }
                                        await dispatch(updateRegisterVerification(data))
                                        await dispatch(
                                          getRegisterVerifications({page: 1, pageSize: 10})
                                        )
                                      } catch (error) {}
                                    }}
                                  >
                                    تایید
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </>
              )}
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
          <div className='d-flex justify-content-center mt-10'>
            <Pagination
              pageSize={state?.registerVerificationPagination.limit}
              onChange={(e) => paginationOnChange(e)}
              current={state?.registerVerificationPagination.page}
              total={state?.registerVerificationPagination.total}
            />
            <Select
              defaultValue={state.registerVerificationPagination.limit}
              style={{width: 120, marginRight: '15px', height: '28px'}}
              onChange={(e) => {
                dispatch(
                  handle_variables({
                    registerVerificationPagination: {
                      ...state.registerVerificationPagination,
                      limit: e,
                    },
                  })
                )
                dispatch(
                  getRegisterVerifications({
                    page: 1,
                    pageSize: e,
                  })
                )
              }}
              options={[
                {value: 10, label: 10},
                {value: 20, label: 20},
                {value: 50, label: 50},
                // { value: 'disabled', label: 'Disabled', disabled: true },
              ]}
            />
          </div>
        </div>

        {/* begin::Body */}
      </div>
    </>
  )
}

export default RegisterVerification
