import axios from 'axios'
export const SecureCall = {
  request: function (url: string, method = 'GET', data = null, header: any = null) {
    let headers = header !== null ? header : {'Content-Type': 'application/json; charset=UTF-8'}
    //Get AccessToken
    try {
      let info: any = localStorage.getItem('persist:root')
      if (info) {
        info = JSON.parse(info)
        if (info.auth) {
          info = JSON.parse(info.auth)
          if (info.user !== undefined) {
            // info = JSON.parse(info.user)
            if (info.isAuthorized) {
              if (info.user.accessToken !== undefined) {
                headers['x-access-token'] = info.user.accessToken
              }
            }
          }
        }
      }
    } catch (exp) {
      //console.log(exp)
    }
    let config: any = {
      url: url,
      method: method,
      headers: headers,
      onDownloadProgress: function () {
        // const percentage = Math.round(
        //     (progressEvent.loaded * 100) / progressEvent.total
        // );
        // // setProgress();
        // if (percentage === 100) {
        //     setTimeout(() => {
        //         //console.log('finished')
        //         // setLoading(false);
        //     }, 400);
        // }
      },
    }
    if (data !== null) {
      config.data = data
    }
    return axios.request(config)
  },
  get: function (url: string, data = null) {
    return this.request(url, 'GET', data)
  },
  post: function (url: string, data = null, header = null) {
    return this.request(url, 'POST', data, header)
  },
  delete: function (url: string, data = null, header = null) {
    return this.request(url, 'DELETE', data, header)
  },
  update: function (url: string, data = null, header = null) {
    return this.request(url, 'PUT', data, header)
  },
}
