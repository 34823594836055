import {API_URL} from '../../../../config/config'
import {SecureCall} from '../../../functions/secaurCall'

export const BANK = 'bank'
export const CHEQUE = 'cheque'
export const PAYMENT = 'payment'
export const Financ = 'finance'

export function get_banks(page: number, pageSize: number) {
  return SecureCall.get(API_URL + BANK + `?page=${page}&limit=${pageSize}`)
}


export function get_cheques(page: number, pageSize: number) {
  return SecureCall.get(API_URL + CHEQUE + `?page=${page}&limit=${pageSize}`)
}

export function set_cheque(data:any) {
  return SecureCall.post(API_URL + CHEQUE ,data)
}


export function get_payments(page: number, pageSize: number) {
  return SecureCall.get(API_URL + PAYMENT + `?page=${page}&limit=${pageSize}`)
}

export function set_payment(data:any) {
  return SecureCall.post(API_URL + PAYMENT ,data)
}


/////////////////////// financ /////////////////////////////
export function get_financs(page: number, pageSize: number) {
  return SecureCall.get(API_URL + Financ + `?page=${page}&limit=${pageSize}`)
}


export function get_financ(id: any) {
  return SecureCall.get(API_URL + Financ + '/' + id)
}
