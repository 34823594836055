import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'

import {toast} from 'react-toastify'
import { get_register_verifications, update_register_verification } from './UserManagmentApi'


interface sendData {
  email: string
  password: string
}

interface CustomError extends AxiosResponse {
  response: {
    data: {
      message: string
    }
  }
}

const initialState = {
  registerVerifications: [],
  registerVerificationTableLoading: false,
  registerVerificationsubmitLoading : false,
  registerVerificationPagination: {
    page: 1,
    limit: 10,
  },
}

///////////////////////// flower define ////////////////////////////
export const getRegisterVerifications = createAsyncThunk('userManagment/getRegisterVerifications', async (data: any) => {
  try {
    const response = await get_register_verifications(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const updateRegisterVerification = createAsyncThunk('userManagment/updateRegisterVerification', async (data: any) => {
  try {
   
    const response = await update_register_verification(data.id, data.data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

// export const setGrade = createAsyncThunk('account/setGrade', async (data: any) => {
//   try {
//     const response = await set_grade(data)
//     return response
//   } catch (err) {
//     const customError = err as CustomError
//     toast.error(customError?.response?.data?.message)
//     Promise.reject(err)
//     throw err
//   }
// })

// export const deleteGrade = createAsyncThunk('account/deleteGrade', async (id: number) => {
//   try {
//     const response = await delete_grade(id)
//     return response
//   } catch (err) {
//     const customError = err as CustomError
//     toast.error(customError?.response?.data?.message)
//     Promise.reject(err)
//     throw err
//   }
// })

// export const getGradeUpdateData = createAsyncThunk(
//   'account/getGradeUpdateData',
//   async (id: number) => {
//     try {
//       const response = await get_grade_update_data(id)
//       return response
//     } catch (err) {
//       const customError = err as CustomError
//       toast.error(customError?.response?.data?.message)
//       Promise.reject(err)
//       throw err
//     }
//   }
// )

// export const updateGrade = createAsyncThunk('account/updateGrade', async ({id, data}:{id:number ,data : any}) => {
//   try {
//     const response = await update_grade(id,data)
//     return response
//   } catch (err) {
//     const customError = err as CustomError
//     toast.error(customError?.response?.data?.message)
//     Promise.reject(err)
//     throw err
//   }
// })

const userManagmentSlice = createSlice({
  name: 'userManagment',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getRegisterVerifications.pending, (state, action) => {
        state.registerVerificationTableLoading = true
      })
      .addCase(getRegisterVerifications.fulfilled, (state, action) => {
        if (action.payload) {
          state.registerVerificationPagination = action.payload.data.pagination
          state.registerVerificationTableLoading = false
          state.registerVerifications = action.payload.data.data
        }
      })
      .addCase(getRegisterVerifications.rejected, (state, action: any) => {
        state.registerVerificationTableLoading = false
      })
      
      .addCase(updateRegisterVerification.pending, (state, action) => {
        state.registerVerificationsubmitLoading = true
      })
      .addCase(updateRegisterVerification.fulfilled, (state, action) => {
        if (action.payload) {
          state.registerVerificationsubmitLoading = false
          toast.success('با موفقیت ثبت شد')
        }
      })
      .addCase(updateRegisterVerification.rejected, (state, action) => {
        state.registerVerificationsubmitLoading = false
        toast.error('خطای سیستمی رخ داده است')
        // throw new Error('Have Error')
      })
     
  },
})

export const {handle_variables} = userManagmentSlice.actions

export const userManagmentState = (state: any) => state.userManagment

export default userManagmentSlice.reducer
