import DatePicker, {utils} from '@hassanmojab/react-modern-calendar-datepicker'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
// import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import moment from 'jalali-moment'
import {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap-v5'

let persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  fixNumbers = function (str: any) {
    if (typeof str === 'string') {
      for (let i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
      }
    }
    return str
  }

const DefaultDatePicker = ({
  from,
  to,
  type,
  placeholder,
  maxDate,
  minDate,
  position,
}: {
  from?: any
  to?: any
  type?: string
  placeholder?: string
  maxDate?: boolean
  minDate?: boolean
  position?: any
}) => {
  const [maximumDate, setMaximumDate] = useState<string[]>([])
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [selectedDayRange, setSelectedDayRange] = useState(undefined)

  const todaySetterHandler = () => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }
    const splittedDate = new Date().toLocaleDateString('fa-IR', options).split('/')
    const todayObject = {
      year: +fixNumbers(splittedDate[0]),
      month: +fixNumbers(splittedDate[1]),
      day: +fixNumbers(splittedDate[2]),
    }
    // setSelectedDayRange({from: todayObject, to: todayObject})
  }

  useEffect(() => {
    const jalaliTo = `${utils('fa').getToday()?.year}/${utils('fa').getToday()?.month}/${
      utils('fa').getToday()?.day
    }`
    let sj: string[] = jalaliTo.split('/')
    setMaximumDate(sj)
  }, [])

  useEffect(() => {
    todaySetterHandler()
  }, [])

  const datepickerInputHandler = (
    {ref}: {ref: any},
    value: any,
    placeholder: string,
    deleteHandler: any
  ) => (
    <>
      <input
        ref={ref}
        className='filtersField'
        type='text'
        style={{position: 'relative'}}
        placeholder={placeholder}
        value={value ? `${value?.year}/${value?.month}/${value?.day}` : ''}
        readOnly
      />
      {/* <FontAwesomeIcon
            onClick={() => {
              deleteHandler();
              pageIndexResest();
            }}
            icon={faTimes}
            style={{ position: "absolute", left: "20px", top: "10px" }}
          ></FontAwesomeIcon> */}
    </>
  )

  const pageIndexResest = () => {
    // if (router.route !== "/") {
    //   router.push("/");
    // }
    // dispatch(handle_variables({currentPage: 1}))
  }

  if (type == 'double') {
    return (
      <>
        <Row>
          <Col lg={6} className='mt-5 mt-lg-0'>
            <DatePicker
              calendarPopperPosition={'bottom'}
              shouldHighlightWeekends
              maximumDate={{
                year: Number(maximumDate[0]),
                month: Number(maximumDate[1]),
                day: Number(maximumDate[2]),
              }}
              onChange={(e: any) => {
                setFromDate(e)
                const jalaliFrom = `${e?.year}/${e?.month}/${e?.day}`
                const fromGeo = moment(jalaliFrom, 'jYYYY/jM/jD').format('YYYY/M/D')
                // dispatch(
                //   handle_variables({
                //     dateRange: { ...dateRange, from: fromGeo },
                //   })
                // );
                //   pageIndexResest()
                from(fromGeo)
              }}
              locale='fa'
              value={fromDate}
              wrapperClassName='siderbar-datepicker'
              calendarSelectedDayClassName='dateRangeSelected'
              renderInput={(e) =>
                datepickerInputHandler(e, fromDate, 'از تاریخ', () => {
                  setFromDate(null)
                  // dispatch(
                  //   handle_variables({
                  //     dateRange: {...dateRange, from: null},
                  //   })
                  // )
                })
              }
            />
          </Col>
          <Col lg={6} className='mt-5 mt-lg-0'>
            <DatePicker
              wrapperClassName='siderbar-datepicker'
              calendarPopperPosition={'bottom'}
              shouldHighlightWeekends
              // maximumDate={utils().getToday()}
              maximumDate={{
                year: Number(maximumDate[0]),
                month: Number(maximumDate[1]),
                day: Number(maximumDate[2]),
              }}
              onChange={(e: any) => {
                setToDate(e)
                const jalaliTo = `${e?.year}/${e?.month}/${e?.day}`

                const toGeo = moment(jalaliTo, 'jYYYY/jM/jD').format('YYYY/M/D')

                const ad = new Date(toGeo)
                const addOneDay = ad.setDate(ad.getDate() + 1)

                let formattedDate = new Date(addOneDay)
                  .toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                  .split(/\//g)

                const sendDate = `${formattedDate[2]}/${formattedDate[0]}/${formattedDate[1]}`

                // dispatch(
                //   handle_variables({
                //     dateRange: { ...dateRange, to: sendDate },
                //   })
                // );
                // pageIndexResest();
                to(sendDate)
              }}
              locale='fa'
              value={toDate}
              renderInput={(e) =>
                datepickerInputHandler(e, toDate, 'تا تاریخ', () => {
                  setToDate(null)
                  // dispatch(
                  //   handle_variables({
                  //     dateRange: {...dateRange, to: null},
                  //   })
                  // )
                })
              }
            />
          </Col>
        </Row>
      </>
    )
  } else {
    return (
      <Row>
        <Col lg={12} className='mt-5 mt-lg-0'>
          <DatePicker
            calendarPopperPosition={position ?? 'top'}
            shouldHighlightWeekends
            maximumDate={
              maxDate
                ? {
                    year: Number(maximumDate[0]),
                    month: Number(maximumDate[1]),
                    day: Number(maximumDate[2]),
                  }
                : undefined
            }
            minimumDate={
              minDate
                ? {
                    year: Number(maximumDate[0]),
                    month: Number(maximumDate[1]),
                    day: Number(maximumDate[2]),
                  }
                : undefined
            }
            onChange={(e: any) => {
              setFromDate(e)
              const jalaliFrom = `${e?.year}/${e?.month}/${e?.day}`
              const fromGeo = moment(jalaliFrom, 'jYYYY/jM/jD').format('YYYY/M/D')
              // dispatch(
              //   handle_variables({
              //     dateRange: { ...dateRange, from: fromGeo },
              //   })
              // );
              //   pageIndexResest()
              from(fromGeo)
            }}
            locale='fa'
            value={fromDate}
            wrapperClassName='siderbar-datepicker'
            calendarSelectedDayClassName='dateRangeSelected'
            renderInput={(e) =>
              datepickerInputHandler(e, fromDate, placeholder ? placeholder : '', () => {
                setFromDate(null)
                // dispatch(
                //   handle_variables({
                //     dateRange: {...dateRange, from: null},
                //   })
                // )
              })
            }
          />
        </Col>
      </Row>
    )
  }
}

export default DefaultDatePicker
