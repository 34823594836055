import {FC, useEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {
  flowerState,
  getFlower,
  getOrganizationFlower,
  handle_variables,
  setFlower,
  setOrganizationFlower,
  updateFlower,
} from '../../redux/flowerSlice'
import {Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import {flowerDefineDefault} from '../../../../typescript/interface'
import type {UploadProps} from 'antd'
import {Button as AntdBtn, Image, message, Upload} from 'antd'
import {toast, ToastContainer} from 'react-toastify'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {authState} from '../../../auth/redux/AuthSlice'

const OrganizationFlowerModal = ({edit = false}: {edit?: boolean}) => {
  const state = useSelector(flowerState)
  const authS = useSelector(authState)
  const dispatch = useDispatch()

  const Schema = Yup.object().shape({
    // flower: Yup.string().required('نام گل نمی تواند خالی باشد'),
  })

  const initialValues = {
    flower: [],
  }

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 1000,
    }
    dispatch(getFlower(data))
  }, [])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values: any) => {
      const flowersId = []

      for await (const num of values.flower) {
        // Expected output: 1
        flowersId.push(num?.id)
      }

      dispatch(handle_variables({OrganizationFlowerAddLoading: true}))

      const data = {
        flowerIds: flowersId,
      }

      dispatch(setOrganizationFlower(data))

      setTimeout(() => {
        dispatch(
          getOrganizationFlower({
            page: state.organizationFlowerPagination.page,
            pageSize: state.organizationFlowerPagination.limit,
            organizationId: authS.user.organizationId,
          })
        )
      }, 1000)
    },
  })

  return (
    <Modal
      size='lg'
      show={state?.addOrganizationFlowerModalOpen}
      onHide={() => dispatch(handle_variables({addOrganizationFlowerModalOpen: false}))}
    >
      <Modal.Header>
        <Modal.Title>افزودن گل</Modal.Title>
      </Modal.Header>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <Modal.Body>
          <Row>
            <Col md={6}>
              <label className='form-label fw-bolder text-dark fs-6'>نام گل</label>
              <DefaultDropDown
                name='flower'
                onChange={(e: any) => {
                  formik.setFieldValue('flower', e)
                }}
                isMulti={true}
                notCloseMenu={true}
                type='default'
                placeholder='نام گل'
                options={state.flowers}
              />
              {formik.touched.flower && formik.errors.flower && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='invalid-error-text' role='alert'>
                      {formik.errors.flower}
                    </span>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(handle_variables({addOrganizationFlowerModalOpen: false}))
            }}
            variant='secondary'
          >
            انصراف
          </Button>
          <Button type='submit' variant='primary'>
            ثبت
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default OrganizationFlowerModal
