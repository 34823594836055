import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {
  delete_sell_request,
  get_sell_request_update_data,
  get_sell_requests,
  set_sell_request,
  update_sell_request,
  set_sell_request_History,
  update_sell_request_history,
  get_sell_managements,
  set_sell_management,
  delete_sell_management,
  get_sell_management_update_data,
  update_sell_management,
  get_flower_details,
  update_flowers_price,
  set_sell_history,
  get_flower_history,
  get_all_cart,
  get_cart,
  get_last_basket,
  get_baskets,
  set_basket,
  get_basket,
  update_basket,
  update_cart,
  get_all_sale_history_dashborad,
  delete_cart,

} from './sellApi'

interface sendData {
  email: string
  password: string
}

interface CustomError extends AxiosResponse {
  response: {
    data: {
      message: string
    }
  }
}

const initialState = {
  sellRequests: [],
  sellRequestTableLoading: false,
  addSellRequestModalOpen: false,
  sellRequestAddLoading: false,
  sellRequestUpdatedefaultValue: {},
  sellRequestUpdatedeDataLoading: false,
  sellRequestPagination: {
    page: 1,
    limit: 10,
  },

  sellRequestHistoryAddLoading: false,

  sellManagements: [],
  sellManagementTableLoading: false,
  addSellManagementModalOpen: false,
  sellManagementAddLoading: false,
  sellManagementUpdatedefaultValue: {},
  sellManagementUpdatedeDataLoading: false,
  sellManagementPagination: {
    page: 1,
    limit: 10,
  },

  carts: [],
  cartTableLoading: false,
  cartPagination: {
    page: 1,
    limit: 10,
  },

  cartDetails: {},
  cartDetailsLoading: false,

  orderEditModalOpen: false,
  orderEditSubmitLoading: false,

  changePriceModalOpen: false,
  changePriceLoading: false,

  changeGradeModalOpen: false,
  changeGradeLoading: false,

  sellRegistrationFlower: [],
  flowerDetailsLoading: false,
  sellRegistrationData: [],

  changeFlowersPriceModalOpen: false,

  setSellHistoryLoading: false,
  flowerHistories: [],
  getFlowerHistoryLoading: false,
  flowerHistoryPagination: {
    page: 1,
    limit: 10,
  },

  lastFlowers: [],
  lastFlowersLoading: false,

  baskets: [],
  basketTableLoading: false,
  addBasketModalOpen: false,
  basketAddLoading: false,
  basketUpdatedefaultValue: [],
  basketUpdatedefaultValueLoading: false,
  basketUpdatedeDataLoading: false,
  basketPagination: {
    page: 1,
    limit: 10,
  },

  saleAllHistoryDashborad: {},
  saleAllHistoryDashboradTableLoading: false,
  saleAllHistoryDashboradPagination: {
    page: 1,
    limit: 10,
  },

  flowersReport: [],
}

export const getSellRequests = createAsyncThunk('sell/getSellRequests', async (data: any) => {
  try {
    const response = await get_sell_requests(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const setSellRequest = createAsyncThunk('sell/setSellRequest', async (data: any) => {
  try {
    const response = await set_sell_request(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const deleteSellReuqst = createAsyncThunk('sell/deleteSellReuqst', async (id: number) => {
  try {
    const response = await delete_sell_request(id)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const getSellRequestUpdateData = createAsyncThunk(
  'sell/getSellRequestUpdateData',
  async (id: number) => {
    try {
      const response = await get_sell_request_update_data(id)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

export const updateSellRequest = createAsyncThunk('sell/updateSellRequest', async (data: any) => {
  try {
    const response = await update_sell_request(data.id, data.data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

/////////////////////////////////////History////////////////////////////////////

export const setSellRequestHistory = createAsyncThunk(
  'sell/setSellRequestHistory',
  async (data: any) => {
    try {
      const response = await set_sell_request_History(data)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

export const updateSellRequestHistory = createAsyncThunk(
  'sell/updateSellRequestHistory',
  async (data: any) => {
    try {
      const response = await update_sell_request_history(data.id, data.data)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

//////////////////////// Sell Managenemt //////////////////////////

export const getAllCart = createAsyncThunk('sell/getAllCart', async (data: any) => {
  try {
    const response = await get_all_cart(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const getCart = createAsyncThunk('sell/getCart', async (id: string) => {
  try {
    const response = await get_cart(id)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const updateCart = createAsyncThunk('sell/updateCart', async (data: any) => {
  try {
    const response = await update_cart(data.id, data.data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const deleteCart = createAsyncThunk('sell/deleteCart', async (id: number) => {
  try {
    const response = await delete_cart(id)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const getSellManagements = createAsyncThunk('sell/getSellManagements', async (data: any) => {
  try {
    const response = await get_sell_managements(
      data.page,
      data.pageSize,
      data.flowerTitle ? data.flowerTitle : '',
      data.gradeTitle ? data.gradeTitle : ''
    )
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const setSellManagement = createAsyncThunk('sell/setSellManagement', async (data: any) => {
  try {
    const response = await set_sell_management(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const deleteSellManagement = createAsyncThunk(
  'sell/deleteSellManagement',
  async (id: number) => {
    try {
      const response = await delete_sell_management(id)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

export const getSellManagementUpdateData = createAsyncThunk(
  'sell/getSellManagementUpdateData',
  async (id: number) => {
    try {
      const response = await get_sell_management_update_data(id)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

export const updateSellManagement = createAsyncThunk(
  'sell/updateSellManagement',
  async ({id, data}: {id: number; data: any}) => {
    try {
      const response = await update_sell_management(id, data)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

/////////////////////////// saleManagementByFlowerAndFromOrganization //////////////////////
export const getFlowerDetails = createAsyncThunk('sell/getFlowerDetails', async (data: any) => {
  try {
    const response = await get_flower_details(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

/////////////////////// update flowers Price //////////////////////
export const updateFlowersPrice = createAsyncThunk(
  'sell/updateFlowersPrice',
  async ({data}: {data: any}) => {
    try {
      const response = await update_flowers_price(data)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

/////////////////////// SALE HISTORY //////////////////////
export const setSellHistory = createAsyncThunk('sell/setSellHistory', async (data: any) => {
  try {
    const response = await set_sell_history(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    console.log('errr', err)
    Promise.reject(err)
    throw err
  }
})

export const getFlowerHistories = createAsyncThunk(
  'sell/getFlowerHistories',
  async (data?: any) => {
    try {
      const from = data?.fromDate ? data?.fromDate : ''
      const to = data?.toDate ? data?.toDate : ''

      const response = await get_flower_history(data, from, to)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

////////////////////////////////// Basket  ///////////////////////////////////////
export const getLastBasket = createAsyncThunk('sell/getLastBasket', async (id: number | string) => {
  try {
    const response = await get_last_basket(id)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const getBaskets = createAsyncThunk('sell/getBaskets', async (data: any) => {
  try {
    const response = await get_baskets(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const setBasket = createAsyncThunk('sell/setBasket', async (data: any) => {
  try {
    const response = await set_basket(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const getBasket = createAsyncThunk('sell/getBasket', async (id: string) => {
  try {
    const response = await get_basket(id)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const updateBasket = createAsyncThunk('sell/updateBasket', async (data: any) => {
  try {
   
    const response = await update_basket(data.id, data.data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})



//////////////////////////////    sale Histroy Dashboard

export const getAllSaleHistoryDashborad = createAsyncThunk(
  'sell/getAllSaleHistoryDashborad',
  async (data: any) => {
    try {
      const from = data?.fromDate ? data?.fromDate : ''
      const to = data?.toDate ? data?.toDate : ''
      const response = await get_all_sale_history_dashborad(data , from ,to)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

const sellSlice = createSlice({
  name: 'sell',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSellRequests.pending, (state, action) => {
        state.sellRequestTableLoading = true
      })
      .addCase(getSellRequests.fulfilled, (state, action) => {
        if (action.payload) {
          state.sellRequestPagination = action.payload.data.pagination
          state.sellRequestTableLoading = false
          state.sellRequests = action.payload.data.data
        }
      })
      .addCase(setSellRequest.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success('با موفقیت درخواست جدید ثبت شد')
          state.sellRequestAddLoading = false
          state.addSellRequestModalOpen = false
        }
      })
      .addCase(deleteSellReuqst.fulfilled, (state, action) => {
        //  state.roles=state.roles.filter(role => role.id !== action.payload)
        toast.success('حذف با موفقیت انجام شد')
        // get_role()
      })
      .addCase(getSellRequestUpdateData.pending, (state, action) => {
        state.sellRequestUpdatedeDataLoading = true
      })
      .addCase(getSellRequestUpdateData.fulfilled, (state, action: any) => {
        state.sellRequestUpdatedeDataLoading = false
        state.sellRequestUpdatedefaultValue = action.payload.data.data
      })
      .addCase(updateSellRequest.fulfilled, (state, action: any) => {
        state.addSellRequestModalOpen = false
        state.sellRequestAddLoading = false
      })
      .addCase(updateSellRequest.rejected, (state, action: any) => {
        state.sellRequestAddLoading = false
      })
      .addCase(setSellRequestHistory.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success('با موفقیت پاسخ ارسال شد')
          state.sellRequestHistoryAddLoading = false
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      })
      .addCase(setSellRequestHistory.rejected, (state, action) => {
        if (action.payload) {
          state.sellRequestHistoryAddLoading = false
        }
      })
      .addCase(updateSellRequestHistory.fulfilled, (state, action: any) => {
        state.sellRequestHistoryAddLoading = false
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      })
      .addCase(updateSellRequestHistory.rejected, (state, action: any) => {
        state.sellRequestHistoryAddLoading = false
      })
      .addCase(getAllCart.pending, (state, action) => {
        state.cartTableLoading = true
      })
      .addCase(getAllCart.fulfilled, (state, action) => {
        if (action.payload) {
          state.cartPagination = action.payload.data.pagination
          state.cartTableLoading = false
          state.carts = action.payload.data.data
        }
      })
      .addCase(getCart.pending, (state, action) => {
        state.cartDetailsLoading = true
      })
      .addCase(getCart.fulfilled, (state, action) => {
        if (action.payload) {
          state.cartDetailsLoading = false
          state.cartDetails = action.payload.data.data
        }
      })
      .addCase(getSellManagements.pending, (state, action) => {
        state.sellManagementTableLoading = true
      })
      .addCase(getSellManagements.fulfilled, (state, action) => {
        if (action.payload) {
          state.sellManagementPagination = action.payload.data.pagination
          state.sellManagementTableLoading = false
          state.sellManagements = action.payload.data.data
        }
      })
      .addCase(setSellManagement.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success('با موفقیت درخواست جدید ثبت شد')
          state.sellManagementAddLoading = false
          state.addSellManagementModalOpen = false
        }
      })
      .addCase(deleteSellManagement.fulfilled, (state, action) => {
        //  state.roles=state.roles.filter(role => role.id !== action.payload)
        toast.success('حذف با موفقیت انجام شد')
        // get_role()
      })
      .addCase(getSellManagementUpdateData.pending, (state, action) => {
        state.sellManagementUpdatedeDataLoading = true
      })
      .addCase(getSellManagementUpdateData.fulfilled, (state, action: any) => {
        state.sellManagementUpdatedeDataLoading = false
        state.sellManagementUpdatedefaultValue = action.payload.data.data
      })
      .addCase(updateSellManagement.fulfilled, (state, action: any) => {
        state.changePriceModalOpen = false
        state.changePriceLoading = false
        state.changeGradeModalOpen = false
        state.changeGradeLoading = false
      })
      .addCase(updateSellManagement.rejected, (state, action: any) => {
        state.changePriceLoading = false
        state.changeGradeLoading = false
      })
      .addCase(getFlowerDetails.pending, (state, action) => {
        state.flowerDetailsLoading = true
      })
      .addCase(getFlowerDetails.fulfilled, (state, action: any) => {
        state.flowerDetailsLoading = false
        // state.sellRegistrationData = action.payload.data.data
        state.sellRegistrationData = [...state.sellRegistrationData, action.payload.data.data]
      })
      .addCase(getFlowerDetails.rejected, (state, action: any) => {
        state.flowerDetailsLoading = false
      })
      .addCase(updateFlowersPrice.fulfilled, (state, action: any) => {
        state.changeFlowersPriceModalOpen = false
        state.changePriceLoading = false
        toast.success('تغییر قیمت با موفقیت انجام شد')
      })
      .addCase(updateFlowersPrice.rejected, (state, action: any) => {
        state.changePriceLoading = false
        state.changeGradeLoading = false
      })
      .addCase(setSellHistory.fulfilled, (state, action) => {
        if (action.payload) {
          state.setSellHistoryLoading = false
        }
      })
      .addCase(setSellHistory.rejected, (state, action) => {
        state.setSellHistoryLoading = false
        throw new Error('Have Error')
      })
      .addCase(getFlowerHistories.pending, (state, action) => {
        state.getFlowerHistoryLoading = true
      })
      .addCase(getFlowerHistories.fulfilled, (state, action) => {
        if (action.payload) {
          state.flowerHistoryPagination = action.payload.data.pagination
          state.getFlowerHistoryLoading = false
          state.flowerHistories = action.payload.data.data
        }
      })
      .addCase(getLastBasket.pending, (state, action) => {
        state.lastFlowersLoading = true
      })
      .addCase(getLastBasket.fulfilled, (state, action) => {
        if (action.payload) {
          state.lastFlowers = action.payload.data?.data
          state.lastFlowersLoading = false
        }
      })
      .addCase(getLastBasket.rejected, (state, action) => {
        state.lastFlowersLoading = false
        throw new Error('Have Error')
      })
      .addCase(getBaskets.pending, (state, action) => {
        state.basketTableLoading = true
      })
      .addCase(getBaskets.fulfilled, (state, action) => {
        if (action.payload) {
          state.basketPagination = action.payload.data.pagination
          state.basketTableLoading = false
          state.baskets = action.payload.data.data
        }
      })
      .addCase(setBasket.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success('با موفقیت درخواست جدید ثبت شد')
          state.basketAddLoading = false
          state.addBasketModalOpen = false
        }
      })
      .addCase(getBasket.pending, (state, action) => {
        state.basketUpdatedefaultValueLoading = true
      })
      .addCase(getBasket.fulfilled, (state, action) => {
        if (action.payload) {
          state.basketUpdatedefaultValueLoading = false
          state.basketUpdatedefaultValue = action.payload.data.data
        }
      })
      .addCase(updateBasket.pending, (state, action) => {
        state.basketUpdatedeDataLoading = true
      })
      .addCase(updateBasket.fulfilled, (state, action) => {
        if (action.payload) {
          state.basketUpdatedeDataLoading = false
          toast.success('با موفقیت ثبت شد')
        }
      })
      .addCase(updateBasket.rejected, (state, action) => {
        state.basketUpdatedeDataLoading = false
        toast.error('خطای سیستمی رخ داده است')
        // throw new Error('Have Error')
      }).addCase(deleteCart.fulfilled, (state, action) => {
        toast.success('حذف با موفقیت انجام شد')
      })
      .addCase(getAllSaleHistoryDashborad.pending, (state, action) => {
        state.saleAllHistoryDashboradTableLoading = true
      })
      .addCase(getAllSaleHistoryDashborad.fulfilled, (state, action) => {
        if (action.payload) {
          state.saleAllHistoryDashboradTableLoading = false
          state.saleAllHistoryDashborad = state.saleAllHistoryDashboradPagination =
            action.payload.data.pagination
          state.saleAllHistoryDashborad = action.payload.data.data
        }
      })
      .addCase(getAllSaleHistoryDashborad.rejected, (state, action) => {
        state.saleAllHistoryDashboradTableLoading = false
        toast.error('خطای سیستمی رخ داده است')
        // throw new Error('Have Error')
      })
  },
})

export const {handle_variables} = sellSlice.actions

export const sellState = (state: any) => state.sell

export default sellSlice.reducer
