import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'

import {toast} from 'react-toastify'
import {delete_grade, get_grades, get_grade_update_data, set_grade, update_grade} from './gradeApi'

interface sendData {
  email: string
  password: string
}

interface CustomError extends AxiosResponse {
  response: {
    data: {
      message: string
    }
  }
}

const initialState = {
  grades: [],
  gradeTableLoading: false,
  addGradeModalOpen: false,
  gradeAddLoading: false,
  gradeUpdatedefaultValue: {},
  gradeUpdatedeDataLoading: false,
  gradePagination: {
    page: 1,
    limit: 10,
  },
}

///////////////////////// flower define ////////////////////////////
export const getGrades = createAsyncThunk('account/getGrades', async (data: any) => {
  try {
    const response = await get_grades(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const setGrade = createAsyncThunk('account/setGrade', async (data: any) => {
  try {
    const response = await set_grade(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const deleteGrade = createAsyncThunk('account/deleteGrade', async (id: number) => {
  try {
    const response = await delete_grade(id)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const getGradeUpdateData = createAsyncThunk(
  'account/getGradeUpdateData',
  async (id: number) => {
    try {
      const response = await get_grade_update_data(id)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

export const updateGrade = createAsyncThunk('account/updateGrade', async ({id, data}:{id:number ,data : any}) => {
  try {
    const response = await update_grade(id,data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

const gradeSlice = createSlice({
  name: 'grade',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getGrades.pending, (state, action) => {
        state.gradeTableLoading = true
      })
      .addCase(getGrades.fulfilled, (state, action) => {
        if (action.payload) {
          state.gradePagination = action.payload.data.pagination
          state.gradeTableLoading = false
          state.grades = action.payload.data.data
        }
      })
      .addCase(setGrade.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success('با موفقیت درجه جدید اضافه شد')
          state.gradeAddLoading = false
          state.addGradeModalOpen = false
        }
      })
      .addCase(setGrade.rejected, (state, action) => {
        state.gradeAddLoading = false 
      })
      .addCase(deleteGrade.fulfilled, (state, action) => {
        toast.success('حذف با موفقیت انجام شد')
      })
      .addCase(getGradeUpdateData.pending, (state, action) => {
        state.gradeUpdatedeDataLoading = true
      })
      .addCase(getGradeUpdateData.fulfilled, (state, action: any) => {
        state.gradeUpdatedeDataLoading = false
        state.gradeUpdatedefaultValue = action.payload.data.data
      })
      .addCase(updateGrade.fulfilled, (state, action: any) => {
        state.addGradeModalOpen = false
        state.gradeAddLoading = false
      })
      .addCase(updateGrade.rejected, (state, action: any) => {
        state.gradeAddLoading = false
      })
  },
})

export const {handle_variables} = gradeSlice.actions

export const gradeState = (state: any) => state.grade

export default gradeSlice.reducer
