import {API_URL} from '../../../../config/config'
import {SecureCall} from '../../../functions/secaurCall'

export const REGISTER_VERIFICATION = 'register-verification'

export function get_register_verifications(page: number, pageSize: number) {
  return SecureCall.get(API_URL + REGISTER_VERIFICATION + `?page=${page}&limit=${pageSize}`)
}

export function update_register_verification(id: number, data: any) {
  return SecureCall.update(API_URL + REGISTER_VERIFICATION + `/${id}`, data)
}

// export function set_grade(data: any) {
//   return SecureCall.post(API_URL + Grade, data)
// }

// export function delete_grade(id: number) {
//   return SecureCall.delete(API_URL + Grade + `/${id}`)
// }

// export function get_grade_update_data(id: number) {
//   return SecureCall.get(API_URL + Grade + `/${id}`)
// }

// export function update_grade(id: number, data: any) {
//   return SecureCall.update(API_URL + Grade + `/${id}`, data)
// }

