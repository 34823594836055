import {FC, useEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import type {UploadProps} from 'antd'
import {Button as AntdBtn, message, Upload} from 'antd'
import {toast, ToastContainer} from 'react-toastify'

import {GridLoader} from 'react-spinners'
import {
  cooperationRequest,
  cooperationState,
  getCoopRequest,
  handle_variables,
  updateCooperationRequest,
} from '../Redux/cooperationSlice'

const SaleDeleyShowModal = ({defaultValue}: {defaultValue?: any}) => {
  const state = useSelector(cooperationState)
  const dispatch = useDispatch()
  console.log('defaultValue', defaultValue)

  const Schema = Yup.object().shape({
    time: Yup.number()
      .typeError('لطفا عدد قابل قبول وارد کنید')
      .min(0, 'درصد همکاری نمیتواند کمتر از 0 باشد')
      .max(100, 'درصد همکاری نمی تواند بیشتر از 100 باشد')
      .required('زمان نمیتواند خالی باشد'),
  })

  const initialValues = {
    time: +defaultValue?.time,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        dispatch(handle_variables({saleDeleyShowModalOpen: false}))
        const data = {
          delaySaleShow: values.time,
        }
        await dispatch(updateCooperationRequest({data, id: defaultValue?.id}))
        await dispatch(
          getCoopRequest({
            page: 1,
            pageSize: 10,
          })
        )
      } catch (error) {}
    },
  })

  return (
    <Modal
      size='lg'
      show={state?.saleDeleyShowModalOpen}
      onHide={() => dispatch(handle_variables({saleDeleyShowModalOpen: false}))}
    >
      <Modal.Header>
        <Modal.Title>تاخیر در نمایش فروش</Modal.Title>
      </Modal.Header>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <Modal.Body>
          {state.userUpdatedeDataLoading ? (
            <div className='d-flex justify-content-center'>
              {' '}
              <GridLoader size={5} color={'gray'} />
            </div>
          ) : (
            <>
              <Row>
                <Col md={6}>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    ناخیر در نمابش فروش به مزرعه دار(ساعت)
                  </label>
                  <input
                    type='number'
                    style={{direction: 'rtl'}}
                    className={'form-control form-control-lg form-control-solid'}
                    {...formik.getFieldProps('time')}
                    placeholder='ناخیر در نمابش فروش به مزرعه دار(ساعت)'
                  />
                  {formik.touched.time && formik.errors.time && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='invalid-error-text' role='alert'>
                          {formik.errors.time}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(handle_variables({saleDeleyShowModalOpen: false}))
            }}
            variant='secondary'
          >
            انصراف
          </Button>
          <Button type='submit' variant='primary'>
            ثبت
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default SaleDeleyShowModal
