import {FC, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../_metronic/helpers'
import PercentageSellModal from './ReturnFlowerRequestModal'
import {useDispatch} from 'react-redux'
import {handle_variables} from '../redux/flowerSlice'
import {handle_variables as main_handle_variables} from '../../../redux/mainSlice'
import FlowerDefinesModal from './ReturnFlowerRequestModal'
import DeleteConfirmModal from '../../../components/deleteConfrimModal'
import ReturnFlowerRequestModal from './ReturnFlowerRequestModal'

const removeItem = () => {
  console.log('herre in remove item')
}

const ReturnFlower = () => {
  const dispatch = useDispatch()

  const [editMode, setEditMode] = useState(false)
  const [defaultValue, setDefaultValue] = useState({flower: '', discription: ''})

  return (
    <>
      {/* <PercentageSellModal /> */}
      {/* <Modal size='lg' show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>تعریف گل</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <label className='form-label fw-bolder text-dark fs-6'>گل</label>
              <select className='form-select form-select-solid form-select-lg fw-bold'>
                <option className='register-user-type' value=''>
                  انتخاب کنید
                </option>
                <option value={1}>لاله</option>
                <option value={2}>گلایل</option>
              </select>
            </Col>

            <Col md={6}>
              <label className='form-label fw-bolder text-dark fs-6'>تعداد</label>
              <input
                placeholder='تعداد'
                autoComplete='off'
                className={'form-control form-control-lg form-control-solid'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <label className='form-label fw-bolder text-dark fs-6'>غرفه</label>
              <select className='form-select form-select-solid form-select-lg fw-bold'>
                <option className='register-user-type' value=''>
                  انتخاب کنید
                </option>
                <option value={1}>درسا(مرتضی رشیدی)</option>
                <option value={2}>زیبا(حسن رحمانی)</option>
                <option value={3}>فدایی(رضا فدایی)</option>
              </select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary'>انصراف</Button>
          <Button variant='primary'>ثبت</Button>
        </Modal.Footer>
      </Modal> */}
      <ReturnFlowerRequestModal edit={editMode} defaultValue={defaultValue} />
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <Button
          onClick={() => {
            dispatch(handle_variables({flowerDefineModalOpen: true}))
            setEditMode(false)
          }}
          className='percentage-sell-btn'
        >
          ثبت مرجوعی
        </Button>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>لیست مرجوعات</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            {/* begin::Menu 2 */}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>Quick Actions</div>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mb-3 opacity-75'></div>
              {/* end::Menu separator */}
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  New Ticket
                </a>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  New Customer
                </a>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu item */}
              <div
                className='menu-item px-3'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='right-start'
                data-kt-menu-flip='left-start, top'
              >
                {/* begin::Menu item */}
                <a href='#' className='menu-link px-3'>
                  <span className='menu-title'>New Group</span>
                  <span className='menu-arrow'></span>
                </a>
                {/* end::Menu item */}
                {/* begin::Menu sub */}
                <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                  {/* begin::Menu item */}
                  <div className='menu-item px-3'>
                    <a href='#' className='menu-link px-3'>
                      Admin Group
                    </a>
                  </div>
                  {/* end::Menu item */}
                  {/* begin::Menu item */}
                  <div className='menu-item px-3'>
                    <a href='#' className='menu-link px-3'>
                      Staff Group
                    </a>
                  </div>
                  {/* end::Menu item */}
                  {/* begin::Menu item */}
                  <div className='menu-item px-3'>
                    <a href='#' className='menu-link px-3'>
                      Member Group
                    </a>
                  </div>
                  {/* end::Menu item */}
                </div>
                {/* end::Menu sub */}
              </div>
              {/* end::Menu item */}
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  New Contact
                </a>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mt-3 opacity-75'></div>
              {/* end::Menu separator */}
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content px-3 py-3'>
                  <a className='btn btn-primary btn-sm px-4' href='#'>
                    Generate Reports
                  </a>
                </div>
              </div>
              {/* end::Menu item */}
            </div>
            {/* end::Menu 2 */}
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div> 
                  </th> */}
                  <th className='min-w-100px'>شماره پیگیری</th>
                  <th className='min-w-140px'>نوع گل</th>
                  <th className='min-w-140px'>مرجوع کننده</th>
                  <th className='min-w-140px'>تعداد</th>
                  <th className='min-w-140px'>تاریخ مرجوع</th>
                  <th className='min-w-140px'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                <tr>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                      965
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      رز
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      امیر غفاری
                    </a>
                  </td>

                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      420
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      1400/03/05
                    </a>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>08:52</span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                      345
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      رز
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      رضا شکاری
                    </a>
                  </td>

                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      200
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                    >
                      1401/11/22
                    </a>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>22:40</span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen019.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <DeleteConfirmModal
        text={'آیا از پاک کردن این گل مطمعن هستید؟'}
        onDelete={() => removeItem()}
      />
    </>
  )
}

export default ReturnFlower
