import axios from 'axios'
import {API_URL} from '../../../../config/config'
import {SecureCall} from '../../../functions/secaurCall'
import {string} from 'yup'

export const LOGIN = 'authentication/login'
export const REGISTER = 'authentication/register'



export function call_login(data: any) {
  return axios.post(API_URL + LOGIN, data)
}

export function call_register(data: any) {
  return axios.post(API_URL + REGISTER, data)
}
