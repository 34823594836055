import {API_URL} from '../../../../config/config'
import {SecureCall} from '../../../functions/secaurCall'

export const GET_PERMISSION = 'permission'
export const SET_ROLE_PERMISSION = 'setRolePermissions'
export const ROLE = 'role'
export const USER = 'user'
export const BUYER = 'buyerUser'
export const CURRENT_USER = 'currentUser'

export function get_permission() {
  return SecureCall.get(API_URL + GET_PERMISSION)
}

export function set_role(data: any) {
  return SecureCall.post(API_URL + ROLE, data)
}

export async function set_role_permission(data: any) {
  return await SecureCall.post(API_URL + SET_ROLE_PERMISSION, data)
}

export function get_role(page: number, pageSize: number) {
  return SecureCall.get(API_URL + ROLE + `?page=${page}&limit=${pageSize}`)
}

export function delete_role(id: number) {
  return SecureCall.delete(API_URL + ROLE + `/${id}`)
}

export function get_role_update_data(id: number) {
  return SecureCall.get(API_URL + ROLE + `/${id}`)
}

export function update_role(id: number, data: any) {
  return SecureCall.update(API_URL + ROLE + `/${id}`, data)
}

////////////////////////////////// USER API //////////////////////////////////

export function get_users(page: number, pageSize: number) {
  return SecureCall.get(API_URL + USER + `?page=${page}&limit=${pageSize}`)
}

export function set_user(data: any) {
  return SecureCall.post(API_URL + USER, data)
}

export function delete_user(id: number) {
  return SecureCall.delete(API_URL + USER + `/${id}`)
}

export function get_user_update_data(id: number) {
  return SecureCall.get(API_URL + USER + `/${id}`)
}

export function update_user(id: number, data: any) {
  return SecureCall.update(API_URL + USER + `/${id}`, data)
}

export function update_current_user(data: any) {
  return SecureCall.update(API_URL + CURRENT_USER, data)
}

////////////////////////////////// Buyer API //////////////////////////////////

export function get_buyers(page: number, pageSize: number) {
  return SecureCall.get(API_URL + BUYER + `?page=${page}&limit=${pageSize}`)
}

export function set_buyer(data: any) {
  return SecureCall.post(API_URL + BUYER, data)
}

export function delete_buyer(id: number) {
  return SecureCall.delete(API_URL + BUYER + `/${id}`)
}

export function get_buyer_update_data(id: number) {
  return SecureCall.get(API_URL + BUYER + `/${id}`)
}

export function update_buyer(id: number, data: any) {
  return SecureCall.update(API_URL + BUYER + `/${id}`, data)
}
