import {GoogleReCaptchaProvider, GoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useDispatch, useSelector} from 'react-redux'
import {handle_variables, mainState} from '../redux/mainSlice'
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react'

const Captcha = () => {
  const dispatch = useDispatch()
  const SECRET_KEY = '6Lej3x4qAAAAAFFYBNdVixgCLSzMWcr9beIuX9cw'
  const state = useSelector(mainState)

  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  const onVerify = useCallback(
    (token: any) => {
      console.log('token :: :: ', token)
      dispatch(handle_variables({captchaToken: token}))
    },
    [state.refreshCaptchaStatus]
  )



  return (
    <>
      <GoogleReCaptchaProvider reCaptchaKey={SECRET_KEY}>
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={state.refreshCaptchaStatus} />
      </GoogleReCaptchaProvider>
    </>
  )
}

export default Captcha
