import {API_URL} from '../../../../config/config'
import {SecureCall} from '../../../functions/secaurCall'

export const Grade = 'grade'

export function get_grades(page: number, pageSize: number) {
  return SecureCall.get(API_URL + Grade + `?page=${page}&limit=${pageSize}`)
}

export function set_grade(data: any) {
  return SecureCall.post(API_URL + Grade, data)
}

export function delete_grade(id: number) {
  return SecureCall.delete(API_URL + Grade + `/${id}`)
}

export function get_grade_update_data(id: number) {
  return SecureCall.get(API_URL + Grade + `/${id}`)
}

export function update_grade(id: number, data: any) {
  return SecureCall.update(API_URL + Grade + `/${id}`, data)
}

