import {Button as AntdBtn, Image, message, Upload, UploadProps} from 'antd'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {
  getBasket,
  getSellRequestUpdateData,
  handle_variables,
  sellState,
  setSellRequestHistory,
  updateBasket,
  updateSellRequestHistory,
} from '../../redux/sellSlice'
import {GridLoader, HashLoader} from 'react-spinners'
import {convertDate} from '../../../../functions/convertDate'
import {authState} from '../../../auth/redux/AuthSlice'
import {converPersianNumberToEnglish} from '../../../../functions/convertPersianNumberToEnglish'
import SellReport from './SellReport'

const SellRequestDetails = () => {
  const [edit, setEdit] = useState(false)
  const [images, setImages] = useState<any>([])
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [data, setData] = useState([])
  const [status, setStatus] = useState(false)
  const [secondRateFlowerId, setSecondRateFlowerId] = useState(0)

  const state = useSelector(sellState)
  const authS = useSelector(authState)
  const dispatch = useDispatch()
  const {id}: any = useParams()
  const history = useHistory()

  const farmerUser = authS?.user?.organization?.organizationType?.id == 1 ? true : false
  // let status = false

  useEffect(() => {
    // dispatch(getSellRequestUpdateData(id))
    dispatch(getBasket(id))
  }, [])

  useEffect(() => {
    if (state?.basketUpdatedefaultValue?.saleRequests?.length > 0) {
      let array: any = []
      state?.basketUpdatedefaultValue?.saleRequests.map((item: any) => {
        if (item?.amount != 0) {
          setStatus(item?.status == 1 ? true : false)
        }

        if (item?.flower?.id == 1) {
          setSecondRateFlowerId(item?.id)
        }

        const data = {
          id: item?.id,
          amount: item?.amount,
          minimumPrice: item?.minimumPrice,
          secondRateAmount: item?.secondRateAmount,
        }
        array.push(data)
      })

      setData(array)
    }
  }, [state?.basketUpdatedefaultValue?.saleRequests])

  const Schema = Yup.object().shape({
    flowerCount: Yup.number()
      // .min(0, 'درصد همکاری نمیتواند کمتر از 0 باشد')
      // .max(100, 'درصد همکاری نمی تواند بیشتر از 100 باشد')
      .required('تعداد نمیتواند خالی باشد'),
    description: Yup.string().required('توضیحات نمیتواند خالی باشد'),
  })

  const initialValues = {
    flowerCount: '',
    description: '',
  }

  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)

      reader.readAsDataURL(file)
    })
  }

  const fileUpload: UploadProps = {
    beforeUpload(file) {
      // if (file.size > 5 * 1024 * 1024) {
      //   toast.error('تصویر انتخاب شده باید کمتر از 1 مگابایت باشد ')
      // }

      convertToBase64(file).then((res: any) => setImages((prevArray: any) => [...prevArray, res]))

      return false
    },
  }

  const onRemove = () => {
    // Your custom logic when the delete icon is clicked
    setImages([])
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        dispatch(handle_variables({basketUpdatedeDataLoading: true}))
        let updatedData: any = ''
        let flowersReport: any = []
        let secondRateAmount: number = 0

        data.map((item: any) => {
          if (item?.id == secondRateFlowerId) {
            secondRateAmount = secondRateAmount + +item?.amount
          } else {
            secondRateAmount = secondRateAmount + +item?.secondRateAmount
          }
        })
        setTimeout(() => {
          updatedData = data.map((item: any) =>
            item.id == secondRateFlowerId ? {...item, amount: secondRateAmount} : item
          )

          //flower reports filter
          flowersReport = state?.flowersReport.filter((item: any) => item?.flowers?.length > 0)
        }, 500)

        setTimeout(async () => {
          const d = {
            status: 1,
            saleRequests: updatedData,
            basketImages: images,
            basketReports: flowersReport,
          }

          const sendData = {
            id: +id,
            data: d,
          }
          await dispatch(updateBasket(sendData))
          history.push('/sell/precentage/request')
        }, 1000)
      } catch (error) {
        toast.error('خطای سیستمی')
      }
    },
  })

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>جزییات درخواست</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
          <div
            onClick={() => {
              history.goBack()
            }}
            style={{flexDirection: 'column', cursor: 'pointer'}}
            className='d-flex justify-content-end'
          >
            بازگشت
            <i
              className='bi bi-arrow-left'
              style={{color: '#000', fontSize: '23px', textAlign: 'center'}}
            ></i>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        {state.sellRequestUpdatedeDataLoading ? (
          <div className='w-100 d-flex justify-content-center my-6'>
            <GridLoader size={5} color={'gray'} />
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='w-100 d-flex justify-content-center align-items-center'>
              <span className='fs-5' style={{fontWeight: '600'}}>
                شماره درخواست
              </span>
              <span className='badge fs-4 badge-light-primary ms-2'>{id}</span>
            </div>

            <div className='w-100 d-flex align-items-center'>
              <span className='fs-5' style={{fontWeight: '600'}}>
                لیست سفارش
              </span>
            </div>

            {/* {state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories?.length > 0 ? (
              <>
                {state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories.map(
                  (item: any) => {
                    return (
                      <div className='sell-request-details-box'>
                        <Row>
                          <Col md={4} className='my-3'>
                            <span className='details-title-info'>{'ارسال شده '}</span>
                            <span>{item?.fromOrganization?.title}</span>
                          </Col>
                          <Col md={4} className='my-3'>
                            <span className='details-title-info'>{'دریافت کننده '}</span>
                            <span>{item?.toOrganization?.title}</span>
                          </Col>
                          <Col md={4} className='my-3'>
                            <span className='details-title-info'>{'نوع گل '}</span>
                            <span>{state?.sellRequestUpdatedefaultValue?.flower?.title}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col className='my-3' md={4}>
                            <span className='details-title-info'>{'تعداد '}</span>
                            <span>{item?.amount}</span>
                          </Col>
                          <Col className='my-3 d-flex' md={4}>
                            <span className='details-title-info'>{'تاریخ '}</span>
                            <span>{convertDate({date: item?.updatedAt, type: 1})}</span>
                          </Col>
                        </Row>
                        {item?.description ? (
                          <Row>
                            <Col className='my-3' md={12}>
                              <span className='details-title-info'>{'توضیحات '}</span>
                              <span>{item?.description}</span>
                            </Col>
                          </Row>
                        ) : null}
                        {item?.image ? (
                          <Row>
                            <Col className='my-3' md={12}>
                              <span className='details-title-info'>{'عکس'}</span>
                              <Image width={200} height={200} src={item?.image} />
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    )
                  }
                )}
              </>
            ) : null} */}

            {!state?.basketUpdatedefaultValueLoading ? (
              <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_signup_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='mt-5'>
                  {/* <p>اطاعات درخواست</p> */}
                  {state?.basketUpdatedefaultValue?.saleRequests?.length > 0 ? (
                    <>
                      {state?.basketUpdatedefaultValue?.saleRequests.map(
                        (item: any, ind: number) => {
                          return (
                            <Row hidden={item?.amount == 0 ? true : false}>
                              <Col md={3} className='my-3'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  نام گل
                                </label>
                                <input
                                  type='text'
                                  disabled
                                  value={item?.flower?.title}
                                  className={'form-control form-control-lg form-control-solid'}
                                  // {...formik.getFieldProps('flowerCount')}
                                  placeholder='نام گل'
                                />
                              </Col>
                              <Col md={2} className='my-3'>
                                <label className='form-label fw-bolder text-dark fs-6'>تعداد</label>
                                <input
                                  type='number'
                                  style={{direction: 'rtl'}}
                                  value={item?.amount}
                                  className={'form-control form-control-lg form-control-solid'}
                                  // {...formik.getFieldProps('flowerCount')}
                                  placeholder='تعداد'
                                />
                              </Col>
                              <Col md={2} className='my-3'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  تعداد درجه دو
                                </label>
                                <input
                                  type='number'
                                  defaultValue={
                                    item?.secondRateAmount != 0 && item?.secondRateAmount
                                  }
                                  disabled={farmerUser || status}
                                  style={{direction: 'rtl'}}
                                  className={'form-control form-control-lg form-control-solid'}
                                  onChange={(e) => {
                                    if (data.length > 0) {
                                      const updatedSaleRequests: any = data

                                      // Update the secondRateAmount of the third item (index 2)
                                      updatedSaleRequests[ind] = {
                                        ...updatedSaleRequests[ind],
                                        secondRateAmount: converPersianNumberToEnglish(
                                          +e.target.value
                                        ), // Replace NEW_VALUE with the desired value
                                      }

                                      // Set the state with the updated saleRequests array
                                      setData(updatedSaleRequests)
                                    }
                                  }}
                                  // {...formik.getFieldProps('secondRateAmount')}
                                  placeholder='تعداد درجه دو'
                                />
                              </Col>
                              <Col md={5} className='my-3'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  توضیحات
                                </label>
                                <input
                                  type='text'
                                  defaultValue={item?.description != 0 ? item?.description : ''}
                                  disabled={farmerUser || status}
                                  className={'form-control form-control-lg form-control-solid'}
                                  // {...formik.getFieldProps('description')}
                                  onChange={(e) => {
                                    if (data.length > 0) {
                                      const updatedSaleRequests: any = data
                                      // console.log("persian",e.target.value)
                                      // console.log("convert",converPersianNumberToEnglish(e.target.value))
                                      // Update the secondRateAmount of the third item (index 2)
                                      updatedSaleRequests[ind] = {
                                        ...updatedSaleRequests[ind],
                                        description: e.target.value, // Replace NEW_VALUE with the desired value
                                      }

                                      // Set the state with the updated saleRequests array
                                      setData(updatedSaleRequests)
                                    }
                                  }}
                                  placeholder='توضیحات'
                                />
                              </Col>
                            </Row>
                          )
                        }
                      )}
                    </>
                  ) : (
                    'موردی یافت نشد'
                  )}
                  {!farmerUser && !status ? (
                    <Row>
                      <Col md={4} className='my-3'>
                        <label className='form-label fw-bolder text-dark fs-6'>آپلود عکس</label>
                        <Upload
                          accept='image/png, image/jpeg'
                          maxCount={5}
                          className='upload-flower-image'
                          {...fileUpload}
                          onRemove={() => onRemove()}
                        >
                          <AntdBtn
                            style={{
                              width: '100%',
                              color: '#5e6278',
                              backgroundColor: '#f5f8fa',
                              borderColor: '#f5f8fa',
                              textAlign: 'start',
                            }}
                          >
                            آپلود عکس
                          </AntdBtn>
                        </Upload>
                      </Col>
                      {/* <Col className='my-3' md={12}>
                       <label className='form-label fw-bolder text-dark fs-6'>توضیحات</label>
                       <textarea
                         className={'form-control form-control-lg form-control-solid'}
                         {...formik.getFieldProps('description')}
                         placeholder='توضیحات'
                       />
                       {formik.touched.description && formik.errors.description && (
                         <div className='fv-plugins-message-container'>
                           <div className='fv-help-block'>
                             <span className='invalid-error-text' role='alert'>
                               {formik.errors.description}
                             </span>
                           </div>
                         </div>
                       )}
                     </Col> */}
                    </Row>
                  ) : (
                    <Row>
                      {state?.basketUpdatedefaultValue?.basketImages?.length > 0 ? (
                        <Col sm={12} className='my-3 d-flex flex-column'>
                          <label className='form-label fw-bolder text-dark fs-6'> عکس</label>
                          <div className='d-flex'>
                            <Image.PreviewGroup
                              preview={{
                                onChange: (current, prev) =>
                                  console.log(`current index: ${current}, prev index: ${prev}`),
                              }}
                            >
                              {state?.basketUpdatedefaultValue?.basketImages?.map((item: any) => {
                                return <Image width={100} src={item?.image} />
                              })}
                            </Image.PreviewGroup>
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                  )}

                  <Row className='my-5'>
                    <span className='fs-5' style={{fontWeight: '600'}}>
                      گزارش سفارش
                    </span>
                    <SellReport />
                  </Row>

                  {!status && !farmerUser ? (
                    <Row>
                      <Col className='d-flex justify-content-end'>
                        {/* <button
                        type='button'
                        onClick={() => setEdit(false)}
                        className='btn  btn-danger '
                      >
                        انصراف
                      </button> */}
                        <button
                          type={state?.basketUpdatedeDataLoading ? 'button' : 'submit'}
                          className='btn  btn-primary ms-2 '
                          onClick={() => {}}
                        >
                          {state?.basketUpdatedeDataLoading ? (
                            <GridLoader size={2} color='white' />
                          ) : (
                            'ثبت'
                          )}
                        </button>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </form>
            ) : (
              <div className='w-100 d-flex justify-content-center align-items-center'>
                <HashLoader size={3} />
              </div>
            )}
            {state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories?.length > 0 &&
            !edit &&
            state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories[
              state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories?.length - 1
            ].toOrganization.id == authS.user.organizationId &&
            state?.sellRequestUpdatedefaultValue.status == null ? (
              <div className='d-flex justify-content-end mt-6'>
                {state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories?.length == 1 ? (
                  <button
                    onClick={() => {
                      dispatch(handle_variables({sellRequestHistoryAddLoading: true}))
                      const info = {
                        id: state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories[
                          state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories
                            ?.length - 1
                        ].id,
                        data: {
                          approved: 0,
                        },
                      }

                      dispatch(updateSellRequestHistory(info))
                    }}
                    type='button'
                    className='btn  btn-danger '
                  >
                    {state?.sellRequestHistoryAddLoading && !acceptLoading ? (
                      <GridLoader size={2} color='white' />
                    ) : (
                      'رد'
                    )}
                  </button>
                ) : null}

                <button
                  type='button'
                  onClick={() => setEdit(true)}
                  style={{color: 'white'}}
                  className='btn ms-2 btn-primary'
                >
                  اصلاح
                </button>
                <button
                  className='btn  btn-primary ms-2'
                  onClick={() => {
                    const info = {
                      id: state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories[
                        state?.sellRequestUpdatedefaultValue?.saleRequestApproveHistories?.length -
                          1
                      ].id,
                      data: {
                        approved: 1,
                      },
                    }

                    dispatch(updateSellRequestHistory(info))
                  }}
                >
                  {state?.sellRequestHistoryAddLoading && acceptLoading ? (
                    <GridLoader size={2} color='white' />
                  ) : (
                    'تایید'
                  )}
                </button>
              </div>
            ) : null}
            {/* end::Table container */}
          </div>
        )}

        {/* begin::Body */}
      </div>
    </>
  )
}

export default SellRequestDetails
