import {FC, useEffect, useState} from 'react'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import PercentageSellModal from './GradeDefineModal'
import {useDispatch, useSelector} from 'react-redux'
import GradeDefinesModal from './GradeDefineModal'
import {GridLoader} from 'react-spinners'
import {toast} from 'react-toastify'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import { deleteGrade, getGrades, getGradeUpdateData, gradeState, handle_variables } from '../redux/gradeSlice'
import { KTSVG } from '../../../../_metronic/helpers'
import DeleteConfirmModal from '../../../components/deleteConfrimModal'



const Grade = () => {
  const dispatch = useDispatch()
  const state = useSelector(gradeState)

  const [editMode, setEditMode] = useState(false)
  const [deleteComponentShow, setDeleteComponentShow] = useState(false)
  const [removeItemId, setRemoveItemId] = useState(0)

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getGrades(data))
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(handle_variables({gradePagination: {...state.gradePagination, page: currentPage}}))
    dispatch(getGrades({page: currentPage, pageSize: state.gradePagination.limit}))
  }

  const closeDeleteComponent = () => {
    setDeleteComponentShow(false)
  }

  const removeItem = async () => {
    if (removeItemId > 0) {
      await dispatch(deleteGrade(removeItemId))
      setDeleteComponentShow(false)
      
        dispatch(
          getGrades({page: state.gradePagination.page, pageSize: state.gradePagination.limit})
        )
      
    } else {
      toast.error('خطای سیستمی')
    }
  }

  return (
    <>
      {state.addGradeModalOpen ? <GradeDefinesModal edit={editMode} /> : null}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <Button
          onClick={() => {
            dispatch(handle_variables({addGradeModalOpen: true}))
            setEditMode(false)
          }}
          className='percentage-sell-btn'
        >
          تعریف درجه جدید
        </Button>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>لیست درجه ها</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
          {/* <div className='card-toolbar'>
           
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
           
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
              data-kt-menu='true'
            >
              
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>Quick Actions</div>
              </div>
   
              <div className='separator mb-3 opacity-75'></div>
        
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  New Ticket
                </a>
              </div>
   
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  New Customer
                </a>
              </div>
           
              <div
                className='menu-item px-3'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='right-start'
                data-kt-menu-flip='left-start, top'
              >
   
                <a href='#' className='menu-link px-3'>
                  <span className='menu-title'>New Group</span>
                  <span className='menu-arrow'></span>
                </a>
       
                <div className='menu-sub menu-sub-dropdown w-175px py-4'>
         
                  <div className='menu-item px-3'>
                    <a href='#' className='menu-link px-3'>
                      Admin Group
                    </a>
                  </div>
   
                  <div className='menu-item px-3'>
                    <a href='#' className='menu-link px-3'>
                      Staff Group
                    </a>
                  </div>
      
                  <div className='menu-item px-3'>
                    <a href='#' className='menu-link px-3'>
                      Member Group
                    </a>
                  </div>
                </div>
      
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  New Contact
                </a>
              </div>

              <div className='separator mt-3 opacity-75'></div>

              <div className='menu-item px-3'>
                <div className='menu-content px-3 py-3'>
                  <a className='btn btn-primary btn-sm px-4' href='#'>
                    Generate Reports
                  </a>
                </div>
              </div>

            </div>

          </div> */}
        </div>

        <div className='card-body py-3'>
          {/* begin::Table container */}
          {state.gradeTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              {state.grades.length < 1 ? (
                'موردی یافت نشد'
              ) : (
                <>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th> */}
                        <th className='min-w-100px'>شماره گل</th>
                        <th className='min-w-140px'>نام گل</th>
                        <th className='min-w-140px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.grades.map((item: any, ind: number) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item.id}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item.title}
                              </a>
                              {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Code: PH</span> */}
                            </td>

                            <td className='text-end'>
                              {/* <a
                                href='#'
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen019.svg'
                                  className='svg-icon-3'
                                />
                              </a> */}
                              <button
                                id='edit'
                                onClick={() => {
                                  dispatch(getGradeUpdateData(item.id))
                                  setEditMode(true)
                                  dispatch(handle_variables({addGradeModalOpen: true}))
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                              <button
                                onClick={(e) => {
                                  setDeleteComponentShow(true)
                                  setRemoveItemId(item.id)
                                }}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </>
              )}
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
          <div className='d-flex justify-content-center mt-10'>
            <Pagination
              pageSize={state?.gradePagination.limit}
              onChange={(e) => paginationOnChange(e)}
              current={state?.gradePagination.page}
              total={state?.gradePagination.total}
            />
            <Select
              defaultValue={state.gradePagination.limit}
              style={{width: 120, marginRight: '15px', height: '28px'}}
              onChange={(e) => {
                dispatch(
                  handle_variables({gradePagination: {...state.gradePagination, limit: e}})
                )
                dispatch(
                  getGrades({
                    page: 1,
                    pageSize: e,
                  })
                )
              }}
              options={[
                {value: 10, label: 10},
                {value: 20, label: 20},
                {value: 50, label: 50},
                // { value: 'disabled', label: 'Disabled', disabled: true },
              ]}
            />
          </div>
        </div>

        {/* begin::Body */}
      </div>

      <DeleteConfirmModal
        text={'آیا از پاک کردن این درجه مطمعن هستید؟'}
        show={deleteComponentShow}
        close={() => closeDeleteComponent()}
        onDelete={() => removeItem()}
      />
    </>
  )
}

export default Grade
