import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {
  delete_cooperation_request,
  get_coomperation_request,
  get_organizations,
  set_cooperation_request,
  update_cooperation_request,
} from './cooperationApi'

interface sendData {
  email: string
  password: string
}

interface CustomError extends AxiosResponse {
  response: {
    data: {
      message: string
    }
  }
}

const initialState = {
  orgs: [],
  orgTableLoading: false,
  CoopRequestModalOpen: false,
  CoopRequestAddLoading: false,
  orgPagination: {
    page: 1,
    limit: 10,
  },
  coops: [],
  coopTableLoading: false,
  coopPagination: {
    page: 1,
    limit: 10,
  },

  saleDeleyShowModalOpen: false,
}

export const getOrg = createAsyncThunk('account/getOrg', async (data: any) => {
  try {
    const response = await get_organizations(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

export const getCoopRequest = createAsyncThunk('account/getCoopRequest', async (data: any) => {
  try {
    const response = await get_coomperation_request(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

export const cooperationRequest = createAsyncThunk(
  'account/CooperationRequest',
  async (data: any) => {
    try {
      const response = await set_cooperation_request(data)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
    }
  }
)

export const updateCooperationRequest = createAsyncThunk(
  'account/updateCooperationRequest',
  async (data: any) => {
    try {
      const response = await update_cooperation_request(data.id, data.data)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
    }
  }
)

export const deleteCooperationRequest = createAsyncThunk(
  'account/deleteCooperationRequest',
  async (data: any) => {
    try {
      const response = await delete_cooperation_request(data.id)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
    }
  }
)

const cooperationSlice = createSlice({
  name: 'cooperation',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOrg.pending, (state, action) => {
        state.orgTableLoading = true
      })
      .addCase(getOrg.fulfilled, (state, action) => {
        if (action.payload) {
          state.orgPagination = action.payload.data.pagination
          state.orgTableLoading = false
          state.orgs = action.payload.data.data
        }
      })
      .addCase(getCoopRequest.fulfilled, (state, action) => {
        if (action.payload) {
          state.coopPagination = action.payload.data.pagination
          state.coopTableLoading = false
          state.coops = action.payload.data.data
        }
      })
      .addCase(cooperationRequest.fulfilled, (state, action) => {
        toast.success('درخواست همکاری با موفقیت ثبت شد')
      })
      .addCase(updateCooperationRequest.pending, (state, action) => {
        state.coopTableLoading = true
      })
      .addCase(deleteCooperationRequest.pending, (state, action) => {
        state.coopTableLoading = true
      })
  },
})

export const {handle_variables} = cooperationSlice.actions

export const cooperationState = (state: any) => state.cooperation

export default cooperationSlice.reducer
