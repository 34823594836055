import {API_URL} from '../../../../config/config'
import {SecureCall} from '../../../functions/secaurCall'

export const SELL_REQUEST = 'saleRequest'
export const SELL_REQUEST_BATCH = 'saleRequestBatch'
export const SELL_REQUEST_APPROVE_HISTORY = 'saleRequestApproveHistory'
export const SELL_MANAGEMENT = 'saleManagement'
export const SELL_MANAGEMENT_BY_FLOWER_FROMORGANIZATION =
  'saleManagementByFlowerAndFromOrganization'
// export const SELL_HISTORY_BATCH = 'saleHistoryBatch'
export const CART = 'cart'
export const SELL_HISTORY = 'saleHistory'
export const LAST_BASKET = 'lastBasket'
export const BASKET = 'basket'
export const SELL_HISTORY_DASHBOARD = 'saleHistoryDashboard'

/////////////////////// sell Request ////////////////////////////
export function get_sell_requests(page: number, pageSize: number) {
  return SecureCall.get(API_URL + SELL_REQUEST + `?page=${page}&limit=${pageSize}`)
}

export function set_sell_request(data: any) {
  return SecureCall.post(API_URL + SELL_REQUEST_BATCH, data)
}

export function delete_sell_request(id: number) {
  return SecureCall.delete(API_URL + SELL_REQUEST + `/${id}`)
}

export function get_sell_request_update_data(id: number) {
  return SecureCall.get(API_URL + SELL_REQUEST + `/${id}`)
}

export function update_sell_request(id: number, data: any) {
  return SecureCall.update(API_URL + SELL_REQUEST + `/${id}`, data)
}

//////////////////////////// sell request history ////////////////////////////

export function set_sell_request_History(data: any) {
  return SecureCall.post(API_URL + SELL_REQUEST_APPROVE_HISTORY, data)
}

export function update_sell_request_history(id: number, data: any) {
  return SecureCall.update(API_URL + SELL_REQUEST_APPROVE_HISTORY + `/${id}`, data)
}

///////////////////////////// Sell Management ///////////////////////////
export function get_sell_managements(
  page: number,
  pageSize: number,
  flowerTitle: string,
  gradeTitle: string
) {
  return SecureCall.get(
    API_URL +
      SELL_MANAGEMENT +
      `?page=${page}&limit=${pageSize}&$flower.title$=${encodeURIComponent(
        flowerTitle
      )}&$grade.title$=${encodeURIComponent(gradeTitle)}`
  )
}

export function set_sell_management(data: any) {
  return SecureCall.post(API_URL + SELL_MANAGEMENT, data)
}

export function delete_sell_management(id: number) {
  return SecureCall.delete(API_URL + SELL_MANAGEMENT + `/${id}`)
}

export function get_sell_management_update_data(id: number) {
  return SecureCall.get(API_URL + SELL_MANAGEMENT + `/${id}`)
}

export function update_sell_management(id: number, data: any) {
  return SecureCall.update(API_URL + SELL_MANAGEMENT + `/${id}`, data)
}

///////////////////////////saleManagementByFlowerAndFromOrganization ///////////////////

export function get_flower_details(data: any) {
  return SecureCall.get(
    API_URL +
      SELL_MANAGEMENT_BY_FLOWER_FROMORGANIZATION +
      `?flowerId=${data.flowerId}&fromOrganizationId=${data.orgId}`
  )
}

/////////////////// update flowers price //////////////////
export function update_flowers_price(data: any) {
  return SecureCall.update(API_URL + SELL_MANAGEMENT, data)
}

/////////////////// SALE HISTORY //////////////////
// export function set_sell_history(data: any) {
//   return SecureCall.post(API_URL + SELL_HISTORY_BATCH, data)
// }

/////////////////// SALE HISTORY //////////////////
export function set_sell_history(data: any) {
  return SecureCall.post(API_URL + CART, data)
}

export function get_all_cart(data: any) {
console.log("data",data)
  const params = new URLSearchParams()

  if (data?.page) {
    // params = params + `page=${page}`
    params.append('page', data?.page.toString())
  }
  if (data?.pageSize) {
    // params = params + `&limit=${pageSize}`
    params.append('limit', data?.pageSize.toString())
  }
  
  if (data?.fromDate) {
    // params = params + `&fromDate=${fromDate}`
    params.append('fromDate', data?.fromDate)
  }
  if (data?.toDate) {
    // params = params + `&toDate=${toDate}`
    params.append('toDate', data?.toDate)
  }

  if (typeof data?.orderId == 'number') {
    params.append('id', data?.orderId)
  }

  if (typeof data?.buyerId == 'number') {
    params.append('buyerUserId', data?.buyerId)
  }

  return SecureCall.get(API_URL + CART + '?' + params)
}
export function get_cart(id: any) {
  return SecureCall.get(API_URL + CART + '/' + id)
}

export function update_cart(id: any, data: any) {
  return SecureCall.update(API_URL + CART + '/' + id, data)
}

export function delete_cart(id: number) {
  return SecureCall.delete(API_URL + CART + `/${id}`)
}

export function get_flower_history(data: any, fromDate?: string, toDate?: string) {
  // let params = '?'
  const params = new URLSearchParams()

  if (data?.page) {
    // params = params + `page=${page}`
    params.append('page', data?.page.toString())
  }
  if (data?.pageSize) {
    // params = params + `&limit=${pageSize}`
    params.append('limit', data?.pageSize.toString())
  }
  if (data?.saleManagementId) {
    // params = params + `&saleManagementId=${saleManagementId}`
    params.append('saleManagementId', data?.saleManagementId.toString())
  }
  if (fromDate) {
    // params = params + `&fromDate=${fromDate}`
    params.append('fromDate', fromDate)
  }
  if (toDate) {
    // params = params + `&toDate=${toDate}`
    params.append('toDate', toDate)
  }

  if (typeof data?.buyerUserId == 'string') {
    params.append('$buyerUserId$', data?.buyerUserId)
  }

  if (typeof data?.fromOrganizationId == 'string') {
    params.append('$saleManagement.fromOrganizationId$', data?.fromOrganizationId)
  }

  if (typeof data?.flowerId == 'string') {
    params.append('$saleManagement.flowerId$', data?.flowerId)
  }

  return SecureCall.get(API_URL + SELL_HISTORY + '?' + params)
}

/////////////////////////////////// Basket ///////////////////////////////////

export function get_last_basket(id: number | string) {
  return SecureCall.get(API_URL + LAST_BASKET + `?toOrganizationId=${id}`)
}

export function get_baskets(page: number, pageSize: number) {
  return SecureCall.get(API_URL + BASKET + `?page=${page}&limit=${pageSize}`)
}

export function set_basket(data: any) {
  return SecureCall.post(API_URL + BASKET, data)
}

export function get_basket(id: number | string) {
  return SecureCall.get(API_URL + BASKET + `/${id}`)
}

export function update_basket(id: number, data: any) {
  return SecureCall.update(API_URL + BASKET + `/${id}`, data)
}



/////////////////////// Sale History Dashboard   /////////////////////////

export function get_all_sale_history_dashborad(data: any, fromDate?: string, toDate?: string) {

  const params = new URLSearchParams()

  if (data?.page) {
    // params = params + `page=${page}`
    params.append('page', data?.page.toString())
  }
  if (data?.pageSize) {
    // params = params + `&limit=${pageSize}`
    params.append('limit', data?.pageSize.toString())
  }
  if (data?.saleManagementId) {
    // params = params + `&saleManagementId=${saleManagementId}`
    params.append('saleManagementId', data?.saleManagementId.toString())
  }
  if (fromDate) {
    // params = params + `&fromDate=${fromDate}`
    params.append('fromDate', fromDate)
  }
  if (toDate) {
    // params = params + `&toDate=${toDate}`
    params.append('toDate', toDate)
  }

  if (typeof data?.toOrganizationId == 'string') {
    params.append('$saleManagement.toOrganizationId$', data?.toOrganizationId)
  }

  if (typeof data?.flowerId == 'string') {
    params.append('$saleManagement.flowerId$', data?.flowerId)
  }

  return SecureCall.get(API_URL + SELL_HISTORY_DASHBOARD + '?' + params)
}
