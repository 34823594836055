import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
import {
  cooperationState,
  deleteCooperationRequest,
  getCoopRequest,
  handle_variables,
  updateCooperationRequest,
} from '../Redux/cooperationSlice'
import {GridLoader} from 'react-spinners'
import {authState} from '../../auth/redux/AuthSlice'
import {isNullOrUndefined} from 'util'
import {convertDate} from '../../../functions/convertDate'
import SaleDeleyShowModal from './SaleDeleyShowModal'

const CoomperationRequest = () => {
  const dispatch = useDispatch()
  const state = useSelector(cooperationState)
  const authS = useSelector(authState)

  const isExhibitor = authS?.user?.organization?.organizationType?.id == 2 ? true : false

  const [defaultValue, setDefaultValue] = useState({
    id: 0,
    time: 0,
  })

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getCoopRequest(data))
  }, [])

  const handleStatus = (data: any) => {
    switch (data.id) {
      case 0:
        return <span className='badge badge-light-danger'>{data.title}</span>

        break

      case 1:
        return <span className='badge badge-light-success'>{data.title}</span>

        break

      case 2:
        return <span className='badge badge-light-primary'>{data.title}</span>

        break

      default:
        break
    }
  }

  const handleButton = (data: any) => {
    switch (data.cooperationStatus.id) {
      case 0:
        return false

        break

      case 1:
        return (
          <>
            {isExhibitor && (
              <button
                type='button'
                className='btn mx-2 btn-primary '
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                onClick={() => {
                  dispatch(handle_variables({saleDeleyShowModalOpen: true}))
                  setDefaultValue({id: data?.id, time: data?.delaySaleShow})
                }}
              >
                تاخیر نمایش فروش
              </button>
            )}

            <button
              type='button'
              onClick={() => {
                dispatch(deleteCooperationRequest({id: data.id}))
                setTimeout(() => {
                  dispatch(
                    getCoopRequest({
                      page: 1,
                      pageSize: 10,
                    })
                  )
                }, 1000)
              }}
              className='btn  btn-danger '
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              لغو همکاری
            </button>
          </>
        )
        break
      case 2:
        return (
          <>
            {authS.user.organizationId == data.fromOrganization.id ? (
              <button
                type='button'
                onClick={() => {
                  dispatch(deleteCooperationRequest({id: data.id}))
                  setTimeout(() => {
                    dispatch(
                      getCoopRequest({
                        page: 1,
                        pageSize: 10,
                      })
                    )
                  }, 1000)
                }}
                className='btn  btn-danger '
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                لغو درخواست
              </button>
            ) : (
              <div>
                <button
                  type='button'
                  className='btn  btn-danger '
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => {
                    dispatch(
                      updateCooperationRequest({data: {cooperationStatusId: 0}, id: data.id})
                    )

                    setTimeout(() => {
                      dispatch(
                        getCoopRequest({
                          page: 1,
                          pageSize: 10,
                        })
                      )
                    }, 1000)
                  }}
                >
                  رد
                </button>
                <button
                  type='button'
                  className='btn ms-2 btn-primary '
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => {
                    dispatch(
                      updateCooperationRequest({data: {cooperationStatusId: 1}, id: data.id})
                    )
                    setTimeout(() => {
                      dispatch(
                        getCoopRequest({
                          page: 1,
                          pageSize: 10,
                        })
                      )
                    }, 1000)
                  }}
                >
                  تایید
                </button>
              </div>
            )}
          </>
        )

        break

      default:
        break
    }
  }

  return (
    <>
      {state?.saleDeleyShowModalOpen && <SaleDeleyShowModal defaultValue={defaultValue} />}
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>لیست درخواست همکاری</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {state.coopTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              {state.coops.length < 1 ? (
                'موردی یافت نشد'
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-100px'>کد درخواست</th>
                      <th className='min-w-140px'>درخواست دهنده</th>
                      <th className='min-w-140px'>دریافت کننده</th>
                      <th className='min-w-140px'>درصد</th>
                      {isExhibitor && <th className='min-w-140px'>تاخیر نمایش فروش</th>}

                      <th className='min-w-140px'>تاریخ درخواست</th>
                      <th className='min-w-140px'>وضعیت</th>
                      <th className='min-w-140px'></th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {state.coops.map((item: any, ind: number) => {
                      return (
                        <tr>
                          <td>
                            <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                              {item.id}
                            </a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                            >
                              {item.fromOrganization.title}
                            </a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                            >
                              {item.toOrganization.title}
                            </a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                            >
                              {item.percent}
                            </a>
                          </td>
                          {isExhibitor && (
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.delaySaleShow}
                              </a>
                            </td>
                          )}

                          <td>{convertDate({date: item.createdAt, type: 1})}</td>
                          <td>{handleStatus(item.cooperationStatus)}</td>

                          <td className='text-end'>{handleButton(item)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default CoomperationRequest
