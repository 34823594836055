import {FC, useEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import type {UploadProps} from 'antd'
import {Button as AntdBtn, message, Upload} from 'antd'
import {toast, ToastContainer} from 'react-toastify'

import {GridLoader} from 'react-spinners'
import {cooperationRequest, cooperationState, handle_variables} from '../Redux/cooperationSlice'

const CooperationRequestModal = ({toOrgId}: {toOrgId: number}) => {
  const state = useSelector(cooperationState)
  const dispatch = useDispatch()
  console.log('stattette', state)

  const Schema = Yup.object().shape({
    // Percent: Yup.string().required('درصد همکاری نمیتواند خالی باشد'),
    Percent: Yup.number()
      .typeError('لطفا عدد قابل قبول وارد کنید')
      .min(0, 'درصد همکاری نمیتواند کمتر از 0 باشد')
      .max(100, 'درصد همکاری نمی تواند بیشتر از 100 باشد')
      .required('درصد همکاری نمیتواند خالی باشد'),
  })

  const initialValues = {
    Percent: '',
    discription: '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: (values) => {
      console.log('herrere', values)
      dispatch(handle_variables({CoopRequestModalOpen: false}))
      const data = {
        toOrganizationId: toOrgId,
        percent: values.Percent,
        discription: values.discription,
      }
      dispatch(cooperationRequest(data))
    },
  })

  return (
    <Modal
      size='lg'
      show={state?.CoopRequestModalOpen}
      onHide={() => dispatch(handle_variables({CoopRequestModalOpen: false}))}
    >
      <Modal.Header>
        <Modal.Title>تعریف کاربر</Modal.Title>
      </Modal.Header>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <Modal.Body>
          {state.userUpdatedeDataLoading ? (
            <div className='d-flex justify-content-center'>
              {' '}
              <GridLoader size={5} color={'gray'} />
            </div>
          ) : (
            <>
              <Row>
                <Col md={6}>
                  <label className='form-label fw-bolder text-dark fs-6'>درصد همکاری</label>
                  <input
                    type='number'
                    style={{direction: 'rtl'}}
                    className={'form-control form-control-lg form-control-solid'}
                    {...formik.getFieldProps('Percent')}
                    placeholder='درصد همکاری(درصد غرفه دار)'
                  />
                  {formik.touched.Percent && formik.errors.Percent && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='invalid-error-text' role='alert'>
                          {formik.errors.Percent}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={12}>
                  <label className='form-label fw-bolder text-dark fs-6'>توضیحات</label>
                  <textarea
                    className={'form-control form-control-lg form-control-solid'}
                    {...formik.getFieldProps('discription')}
                    placeholder='توضیحات'
                  />
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(handle_variables({CoopRequestModalOpen: false}))
            }}
            variant='secondary'
          >
            انصراف
          </Button>
          <Button type='submit' variant='primary'>
            ثبت
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CooperationRequestModal
