import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Cheque from './components/Cheque/Cheque'
import Payment from './components/payment/Payment'
import FinancialAccount from './components/FinancialAccount/FinancialAccount'



const FinancPage: React.FC = () => {
  return (
    <>
      {/* <ProfileHeader /> */}
      <Switch>
        <Route path='/financ/cheque'>
          <Cheque />
        </Route>
        <Route path='/financ/payment'>
          <Payment />
        </Route>
        <Route path='/financ/financial-account'>
          <FinancialAccount />
        </Route>
      </Switch>
    </>
  )
}

export default FinancPage
