import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {useDispatch, useSelector} from 'react-redux'
import Pagination from 'rc-pagination'
import {allData} from './constants'
import {getFlowerHistories, handle_variables, sellState} from '../../redux/sellSlice'
import {Link, useParams} from 'react-router-dom'
import {GridLoader} from 'react-spinners'
import {Select} from 'antd'
import {convertDate} from '../../../../functions/convertDate'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'
import {toast} from 'react-toastify'
import {authState} from '../../../auth/redux/AuthSlice'
import DatePicker, {utils} from '@hassanmojab/react-modern-calendar-datepicker'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import moment from 'jalali-moment'
import {Button, Col, Row} from 'react-bootstrap-v5'
import DefaultDatePicker from '../../../../components/DefaultDatePicker'

const SaleFlowerHistory = () => {
  const dispatch = useDispatch()
  const state = useSelector(sellState)
  const authS = useSelector(authState)

  const [filterDate, setFilterDate] = useState({from: '', to: ''})

  const isExhibitor = authS?.user?.organization?.organizationType?.id == 2 ? true : false
  const {id}: any = useParams()

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
      saleManagementId: id,
    }
    dispatch(getFlowerHistories(data))
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        flowerHistoryPagination: {...state.flowerHistoryPagination, page: currentPage},
      })
    )
    dispatch(
      getFlowerHistories({
        page: currentPage,
        pageSize: state.flowerHistoryPagination.limit,
        saleManagementId: id,
      })
    )
  }

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}

        <div className='card-header sale-flower-history-header border-0 pt-5'>
          <Row className='w-100'>
            <Col lg={2}>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>لیست فروش اخیر</span>
              </h3>
            </Col>

            <Col lg={4}>
              <DefaultDatePicker
                from={(e: string) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    from: e,
                  }))
                }
                to={(e: any) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    to: e,
                  }))
                }
                type='double'
              />
            </Col>
            <Col lg={1} className='mt-5 mt-lg-0'>
              <Button
                onClick={() => {
                  const data = {
                    page: 1,
                    pageSize: 10,
                    saleManagementId: id,
                    fromDate: filterDate.from,
                    toDate: filterDate.to,
                  }
                  dispatch(getFlowerHistories(data))
                }}
              >
                جستجو
              </Button>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {state.getFlowerHistoryLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              {state.flowerHistories?.length < 1 ? (
                'موردی یافت نشد'
              ) : (
                <>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th> */}
                        <th className='min-w-60px'>شماره سفارش</th>
                        {isExhibitor && <th className='min-w-120px'>خریدار</th>}
                        <th className='min-w-120px'>تعداد</th>
                        <th className='min-w-120px'>قیمت(تومان)</th>
                        <th className='min-w-120px'>تاریخ</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.flowerHistories?.map((item: any, ind: number) => {
                        return (
                          <tr>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item.id}
                              </a>
                            </td>
                            {isExhibitor && (
                              <td>
                                <a
                                  href='#'
                                  className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                                >
                                  {item?.buyerUser?.firstName} {item?.buyerUser?.lastName}
                                </a>
                              </td>
                            )}

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.amount}
                              </a>
                              {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Code: Paid</span> */}
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item.finalPrice}
                              </a>
                            </td>

                            <td className='text-dark fw-bolder text-hover-primary fs-6'>
                              {convertDate({date: item.createdAt, type: 1})}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </>
              )}
              {/* end::Table */}
            </div>
          )}

          {/* end::Table container */}

          <div className='d-flex justify-content-center mt-10'>
            <Pagination
              pageSize={state?.flowerHistoryPagination.limit}
              onChange={(e) => paginationOnChange(e)}
              current={state?.flowerHistoryPagination.page}
              total={state?.flowerHistoryPagination.total}
            />
            <Select
              defaultValue={state.flowerHistoryPagination.limit}
              style={{width: 120, marginRight: '15px', height: '28px'}}
              onChange={(e) => {
                dispatch(
                  handle_variables({
                    flowerHistoryPagination: {...state.flowerHistoryPagination, limit: e},
                  })
                )
                dispatch(
                  getFlowerHistories({
                    page: 1,
                    pageSize: e,
                    saleManagementId: id,
                  })
                )
              }}
              options={[
                {value: 10, label: 10},
                {value: 20, label: 20},
                {value: 50, label: 50},
                // { value: 'disabled', label: 'Disabled', disabled: true },
              ]}
            />
          </div>
        </div>

        {/* begin::Body */}
      </div>
    </>
  )
}

export default SaleFlowerHistory
