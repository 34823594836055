

export const convertDate = ({date, type = 0}: {date?: any; type?: number}) => {
  // type 0 is return just date
  // type 0 is return date and clock

  const formatdate = new Date(date).toLocaleDateString('fa-IR')
  const time = new Date(date).toLocaleTimeString('fa-IR')

  if (type == 1) {
    return (
      <>
        <a href='#' className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
          {formatdate}
        </a>
        <span className='text-muted fw-bold text-muted d-block fs-7'>{time}</span>
      </>
    )
  }

  return false
}
