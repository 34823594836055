import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {call_login, call_register} from './AuthApi'
import {rejects} from 'assert'
import {ErrorMessage} from 'formik'
import { refreshCaptcha } from '../../../redux/mainSlice'

interface sendData {
  email: string
  password: string
}

interface CustomError extends AxiosResponse {
  response: {
    data: {
      message: string
    }
  }
}

const initialState = {
  user: null,
  isAuthorized: false,
  registerLoading: false,
  loginLoading: false,
}

export const login = createAsyncThunk('auth/login', async (data: any) => {
  try {
    const response = await call_login(data)
    return response.data
  } catch (err) {
    console.log('herrere reject')
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    // return 
  
    return Promise.reject(err)
  
  }
})

export const register = createAsyncThunk('auth/register', async (data: any) => {
  try {
    const response = await call_register(data)
    return response.data
  } catch (err) {
    
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    return Promise.reject(err)
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        console.log('login success')
        if (action.payload) {
          state.loginLoading = false
          state.isAuthorized = true
          state.user = action.payload.data
          // state.province=action.payload.data
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loginLoading = false
        console.log("herer")
      })
      .addCase(register.fulfilled, (state, action) => {
        console.log('login reject')
        if (action.payload) {
          // state.registerLoading = false
          // state.isAuthorized = true
          // state.user = action.payload.data
          toast.success(action.payload.message)
        }
      })
      .addCase(register.rejected, (state, action) => {
        state.registerLoading = false
        throw 'have error'
      })
  },
})

export const {handle_variables} = authSlice.actions

export const authState = (state: any) => state.auth

export default authSlice.reducer
