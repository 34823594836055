import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import ReturnFlower from './components/ReturnFlower'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/flower/defines',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReturnFlowerPage: React.FC = () => {
  return (
    <>
      {/* <ProfileHeader /> */}
      <Switch>
        <Route path='/return-flower'>
          {/* <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle> */}
          <ReturnFlower />
        </Route>

        <Redirect from='/crafted/pages/profile' exact={true} to='/crafted/pages/profile/overview' />
        <Redirect to='/crafted/pages/profile/overview' />
      </Switch>
    </>
  )
}

export default ReturnFlowerPage
