import moment from 'jalali-moment'

export const stringPrice = ({data}: {data: string | number}) => {
  let partLength = 3

  if (typeof data === 'number') {
    data = data.toString()
  }

  let parts = []

  if (data?.length) {
    for (let i = data.length; i > 0; i -= partLength) {
      let startIndex = Math.max(0, i - partLength)
      let endIndex = i
      parts.unshift(data.slice(startIndex, endIndex))
    }

    let returnData = ''
    for (let i = parts.length; i > 0; i--) {
      if (i == 1 && parts[parts.length - i] !== '000') {
        returnData = returnData + parts[parts.length - i]
      }
      if (i == 2 && parts[parts.length - i] != '000') {
        returnData = returnData + parts[parts.length - i] + 'هزار '
      }
      if (i == 3 && parts[parts.length - i] != '000') {
        returnData = returnData + parts[parts.length - i] + 'میلیون '
      }
    }

    if (returnData) {
      returnData = returnData + 'تومان'
    }

    return returnData
  } else {
    return false
  }
}
