import {FC, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {
  flowerState,
  getFlower,
  handle_variables,
  setFlower,
  updateFlower,
} from '../../redux/flowerSlice'
import {Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import {flowerDefineDefault} from '../../../../typescript/interface'
import type {UploadProps} from 'antd'
import {Button as AntdBtn, Image, message, Upload} from 'antd'
import {toast, ToastContainer} from 'react-toastify'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {BeatLoader, GridLoader} from 'react-spinners'

const convertToBlob = (base64String: any, mimeType: any) => {
  // Remove the data URL prefix (e.g., "data:image/jpeg;base64,")
  const base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, '')

  // Convert the base64 string to a Blob
  const byteCharacters = atob(base64WithoutPrefix)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], {type: mimeType})

  return blob
}

const convertToFile = (base64String: any, mimeType: any, fileName: any) => {
  const blob = convertToBlob(base64String, mimeType)

  // Create a File object from the Blob
  const file = new File([blob], fileName, {type: mimeType})
  return file
}

const decode = (flowerImage: any) => {
  const mimeType = 'image/jpeg'
  const fileName = 'example.jpg'
  const file = convertToFile(flowerImage, mimeType, fileName)
  console.log('tessssst', file)
}

const FlowerDefinesModal = ({
  edit = false,
  defaultValue,
}: {
  edit?: boolean
  defaultValue?: flowerDefineDefault
}) => {
  const state = useSelector(flowerState)
  const dispatch = useDispatch()

  const [fileList, setFileList] = useState([])
  const [flowerImage, setFlowerImage] = useState(null)

  const convertToBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)

      reader.readAsDataURL(file)
    })
  }

  const fileUpload: UploadProps = {
    beforeUpload(file) {
      console.log('file', file)
      if (file.size > 1 * 1024 * 1024) {
        toast.error('تصویر انتخاب شده باید کمتر از 1 مگابایت باشد ')
      }

      convertToBase64(file).then((res: any) => setFlowerImage(res))

      return false
      if (fileList.length > 0) {
        toast.error('نمی توانید بیشتر از یک عکس انتخاب کنید')
        // const index = fileList.indexOf(file)
        const newFileList = fileList.slice()
        // newFileList.splice(index, 1)
        setFileList(newFileList)
        // setFileList(file)
        return false
      }
    },
  }

  const Schema = Yup.object().shape({
    flowerName: Yup.string().required('نام گل نمی تواند خالی باشد'),
  })

  const initialValues = {
    flowerName:
      edit && state.flowerUpdatedefaultValue?.title ? state.flowerUpdatedefaultValue?.title : '',
  }

  const onRemoveImage = () => {
    // Your custom logic when the delete icon is clicked
    setFlowerImage(null)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: (values) => {
      console.log('herrere', values)
      console.log('flowerImage', flowerImage)
      dispatch(handle_variables({flowerAddLoading: true}))

      //Condition for create and update
      if (edit) {
        //update
        const data = {
          title: values.flowerName,
        }
        const id = state.flowerUpdatedefaultValue.id
        const info = {
          id,
          data,
        }
        dispatch(updateFlower(info))
      } else {
        //create

        const data = {
          title: values.flowerName,
          image: flowerImage,
        }

        dispatch(setFlower(data))
      }
      setTimeout(() => {
        dispatch(
          getFlower({page: state.flowerPagination.page, pageSize: state.flowerPagination.limit})
        )
      }, 1000)
    },
  })

  return (
    <Modal
      size='lg'
      show={state?.addFlowerModalOpen}
      onHide={() => dispatch(handle_variables({addFlowerModalOpen: false}))}
    >
      <Modal.Header>
        <Modal.Title>ویرایش سفارش</Modal.Title>
      </Modal.Header>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <Modal.Body>
          {state.flowerUpdatedeDataLoading ? (
            <div className='d-flex justify-content-center'>
              {' '}
              <GridLoader size={5} color={'gray'} />
            </div>
          ) : (
            <Row>
              <Col md={6}>
                <label className='form-label fw-bolder text-dark fs-6'>نام گل</label>

                <input
                  className={'form-control form-control-lg form-control-solid'}
                  {...formik.getFieldProps('flowerName')}
                  placeholder='نام گل'
                />
                {formik.touched.flowerName && formik.errors.flowerName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='invalid-error-text' role='alert'>
                        {formik.errors.flowerName}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
              <Col lg={6}>
                <label className='form-label fw-bolder text-dark fs-6'>آپلود عکس</label>
                <Upload
                  accept='image/png, image/jpeg'
                  maxCount={1}
                  className='upload-flower-image'
                  {...fileUpload}
                  listType={
                    state?.flowerUpdatedefaultValue?.flowerImages?.length > 0
                      ? 'picture-card'
                      : 'text'
                  }
                  onRemove={() => onRemoveImage()}
                  defaultFileList={
                    state?.flowerUpdatedefaultValue?.flowerImages?.length > 0
                      ? [
                          {
                            uid: '-1',
                            name: 'Default Image',
                            status: 'done',
                            url: state?.flowerUpdatedefaultValue?.flowerImages[0]?.image,
                          },
                        ]
                      : []
                  }
                >
                  <AntdBtn
                    style={{
                      width: '100%',
                      color: '#5e6278',
                      backgroundColor: '#f5f8fa',
                      borderColor: '#f5f8fa',
                      textAlign: 'start',
                    }}
                  >
                    آپلود عکس
                  </AntdBtn>
                </Upload>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(handle_variables({addFlowerModalOpen: false}))
            }}
            variant='secondary'
          >
            انصراف
          </Button>
          <Button disabled={state?.flowerAddLoading ? true : false} type='submit' variant='primary'>
            {state?.flowerAddLoading ? (
              <div>
                <BeatLoader size={6} color={'white'} />
              </div>
            ) : (
              'ثبت'
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default FlowerDefinesModal
