import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import PercentageSellModal from './PercentageSellModal'
import {useDispatch, useSelector} from 'react-redux'
import Pagination from 'rc-pagination'
import {allData} from './constants'
import {
  deleteSellReuqst,
  getBaskets,
  getSellRequestUpdateData,
  getSellRequests,
  handle_variables,
  sellState,
} from '../../redux/sellSlice'
import {Link} from 'react-router-dom'
import {GridLoader} from 'react-spinners'
import {Select} from 'antd'
import {convertDate} from '../../../../functions/convertDate'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'
import {toast} from 'react-toastify'
import {authState} from '../../../auth/redux/AuthSlice'

const Percentage = () => {
  const dispatch = useDispatch()
  const state = useSelector(sellState)
  const authS = useSelector(authState)

  const [editMode, setEditMode] = useState(false)
  const [deleteComponentShow, setDeleteComponentShow] = useState(false)
  const [removeItemId, setRemoveItemId] = useState(0)

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    // dispatch(getSellRequests(data))
    dispatch(getBaskets(data))
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(handle_variables({basketPagination: {...state.basketPagination, page: currentPage}}))
    dispatch(getBaskets({page: currentPage, pageSize: state.basketPagination.limit}))
  }

  const closeDeleteComponent = () => {
    setDeleteComponentShow(false)
  }

  const removeItem = () => {
    if (removeItemId > 0) {
      dispatch(deleteSellReuqst(removeItemId))
      setDeleteComponentShow(false)
      setTimeout(() => {
        dispatch(
          getBaskets({
            page: state.basketPagination.page,
            pageSize: state.basketPagination.limit,
          })
        )
      }, 1000)
    } else {
      toast.error('خطای سیستمی')
    }
  }

  const handleStatus = (item: any) => {
    switch (item.status) {
      case 1:
        return <span className='badge badge-light-success'>تایید شده</span>

        break

      case 0:
        return <span className='badge badge-light-danger'>رد شده</span>

        break

      default:
        if (item?.lastSaleRequestApproveHistory[0]?.toOrganizationId == authS.user.organizationId) {
          return <span className='badge badge-light-primary'>در انتظار تایید شما</span>
        } else {
          return <span className='badge badge-light-primary'>در حال بررسی </span>
        }

        break
    }
  }

  console.log('authsss', authS)
  console.log('stateeee', state)
  return (
    <>
      {state.addBasketModalOpen ? <PercentageSellModal edit={editMode} /> : null}
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        {authS?.user?.organization?.organizationType?.id == 1 ? (
          <Button
            onClick={() => {
              dispatch(handle_variables({addBasketModalOpen: true}))
              setEditMode(false)
            }}
            className='percentage-sell-btn'
          >
            فروش
          </Button>
        ) : null}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
              {authS?.user?.organization?.organizationType?.id == 1
                ? 'لیست فروش اخیر'
                : 'لیست دریافتی های اخیر'}
            </span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {state.basketTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              {state.baskets.length < 1 ? (
                'موردی یافت نشد'
              ) : (
                <>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th> */}
                        <th className='min-w-150px'>شماره سفارش</th>
                        <th className='min-w-140px'>
                          {authS?.user?.organization.organizationType.id == 1 ? 'غرفه' : 'گلخانه'}
                        </th>
                        {/* <th className='min-w-120px'>گل</th>
                        <th className='min-w-120px'>تعداد</th>
                        <th className='min-w-120px'>حداقل قیمت(تومان)</th> */}
                        <th className='min-w-120px'>تاریخ</th>
                        <th className='min-w-120px'>وضعیت</th>
                        <th className='min-w-100px text-end'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.baskets.map((item: any, ind: number) => {
                        return (
                          <tr>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item.basketId}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item.toOrganization.id == authS.user.organizationId
                                  ? item.fromOrganization.title
                                  : item.toOrganization.title}
                              </a>
                            </td>
                            {/* <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item.flower.title}
                              </a>
                            </td> */}
                            {/* <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item.initialAmount}
                              </a>
                            </td> */}
                            {/* <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.minimumPrice}
                              </a>
                            </td> */}
                            <td className='text-dark fw-bolder text-hover-primary fs-6'>
                              {convertDate({date: item.updatedAt, type: 1})}
                            </td>
                            <td>{handleStatus(item)}</td>
                            <td className='text-end'>
                              <Link
                                to={`/sell/precentage/request-details/${item.basketId}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen019.svg'
                                  className='svg-icon-3'
                                />
                              </Link>

                              {item.saleRequestApproveHistoryCount == 1 &&
                              item.fromOrganizationId == authS.user.organizationId &&
                              item.status == null ? (
                                <>
                                  {' '}
                                  <button
                                    onClick={() => {
                                      dispatch(getSellRequestUpdateData(item.id))
                                      setEditMode(true)
                                      dispatch(handle_variables({addSellRequestModalOpen: true}))
                                    }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      setDeleteComponentShow(true)
                                      setRemoveItemId(item.id)
                                    }}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                </>
                              ) : null}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </>
              )}
              {/* end::Table */}
            </div>
          )}

          {/* end::Table container */}

          <div className='d-flex justify-content-center mt-10'>
            <Pagination
              pageSize={state?.basketPagination.limit}
              onChange={(e) => paginationOnChange(e)}
              current={state?.basketPagination.page}
              total={state?.basketPagination.total}
            />
            <Select
              defaultValue={state.basketPagination.limit}
              style={{width: 120, marginRight: '15px', height: '28px'}}
              onChange={(e) => {
                dispatch(
                  handle_variables({
                    basketPagination: {...state.basketPagination, limit: e},
                  })
                )
                dispatch(
                  getBaskets({
                    page: 1,
                    pageSize: e,
                  })
                )
              }}
              options={[
                {value: 10, label: 10},
                {value: 20, label: 20},
                {value: 50, label: 50},
                // { value: 'disabled', label: 'Disabled', disabled: true },
              ]}
            />
          </div>
        </div>

        <DeleteConfirmModal
          text={'آیا از پاک کردن این درخواست مطمعن هستید؟'}
          show={deleteComponentShow}
          close={() => closeDeleteComponent()}
          onDelete={() => removeItem()}
        />
        {/* begin::Body */}
      </div>
    </>
  )
}

export default Percentage
