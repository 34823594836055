import {Button as AntdBtn, Image, message, Upload, UploadProps} from 'antd'
import {useFormik} from 'formik'
import {useEffect, useRef, useState} from 'react'
import {Button, Col, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {
  getFlowerDetails,
  getSellManagements,
  getSellRequestUpdateData,
  handle_variables,
  sellState,
  setSellHistory,
  setSellRequestHistory,
  updateSellRequestHistory,
} from '../../redux/sellSlice'
import {BeatLoader, GridLoader} from 'react-spinners'
import {convertDate} from '../../../../functions/convertDate'
import {authState} from '../../../auth/redux/AuthSlice'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {stringPrice} from '../../../../functions/stringPrice'
import {QrReader} from 'react-qr-reader'
import {KTSVG} from '../../../../../_metronic/helpers'
import {accountState, getBuyers} from '../../../accounts/Redux/AccountSlice'
import {priceSlice} from '../../../../functions/priceSlice'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import DefaultDatePicker from '../../../../components/DefaultDatePicker'
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker'
// import {PrintComponents} from 'react-print-components'
import {ComponentToPrint} from './PrintInvoice'
import {useReactToPrint} from 'react-to-print'
import {financState, getBanks} from '../../../financ/redux/financSlice'
import QRScanner from '../../../../components/QRScanner'

interface FlowerCountItem {
  amount: number
}

const SellRegistration = () => {
  const [edit, setEdit] = useState(false)
  const [image, setImage] = useState(null)
  const [acceptLoading, setAcceptLoading] = useState(false)

  const state = useSelector(sellState)
  const authS = useSelector(authState)
  const accountS = useSelector(accountState)
  const financS = useSelector(financState)
  const dispatch = useDispatch()
  const {search} = useLocation()
  const params = new URLSearchParams(search)
  const org = params.get('org')
  const flower = params.get('flower')
  const history = useHistory()

  const [scanData, setScanData] = useState<any>(null)
  const [buyersExist, setBuyersExist] = useState(false)
  const [buyer, setBuyer] = useState([])
  const [discount, setDiscount] = useState(0)
  const [promissoryNotes, setPromissoryNotes] = useState<any>([])
  const [printData, setPrintData] = useState<any>(null)
  const [selectFlowerManualStatus, setSelectFlowerManualStatus] = useState<number>(0)
  const [manualFlowers, setManualFlowers] = useState<any>([])
  const [manualFlowersSelected, setManualFlowersSelected] = useState<any>([])

  const inputRefs: any = useRef([])

  useEffect(() => {
    // This use Effect for scan qr
    if (scanData) {
      if (state?.sellRegistrationFlower?.length > 0) {
        const isDuplicate = state.sellRegistrationFlower.some((data: any) => {
          return data.flowerId == scanData.FLOWER && data.orgId == scanData.ORG
        })

        if (!isDuplicate) {
          const data = {
            flowerId: scanData.FLOWER,
            orgId: scanData.ORG,
          }
          const sellRegistrationFlower = [...state.sellRegistrationFlower, data]

          dispatch(handle_variables({sellRegistrationFlower: sellRegistrationFlower}))
          dispatch(getFlowerDetails(data))
        }
      } else {
        const data = {
          flowerId: scanData.FLOWER,
          orgId: scanData.ORG,
        }

        dispatch(handle_variables({sellRegistrationFlower: [data]}))
        dispatch(getFlowerDetails(data))
      }
    }
  }, [scanData])

  // this useEffect for manual select flowers
  useEffect(() => {
    setManualFlowers([])
    if (state.sellManagements.length > 0) {
      state.sellManagements.map((item: any) => {
        const data = {
          value: +item?.flower?.id + '-' + item?.fromOrganization?.id,
          title: item?.flower?.title + ' ' + `(${item?.fromOrganization?.title})`,
        }

        setManualFlowers((prev: any) => [...prev, data])
      })
    }
  }, [state.sellManagements])

  useEffect(() => {
    //get buyers
    dispatch(getBuyers({page: 1, pageSize: 500}))

    //get banks
    dispatch(getBanks({page: 1, pageSize: 50}))
  }, [])

  if (!buyersExist && accountS?.buyers?.length > 0) {
    const mappedArray = accountS?.buyers?.map((item: any) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    }))
    setBuyer(mappedArray)
    setBuyersExist(true)
  }

  const Schema = Yup.object().shape({
    // saleHistories: Yup.number()
    //   .required('تعداد نمیتواند خالی باشد'),
    buyerUserId: Yup.string().required('مشتری نمیتواند خالی باشد'),
  })

  const initialValues = {
    flowerCount: [],
    buyerUserId: '',
    cashAmount: 0,
  }

  const handleQRScan = (qrData: any) => {
    // Parse the QR code data

    // const parsedData = JSON.parse(qrData)
    console.log('in handle', qrData)
    setScanData(qrData)
  }

  const removeRow = (item: any) => {
    const sellRegistrationFlowerFilter = state?.sellRegistrationFlower?.filter(
      (x: any) => x?.flowerId == item?.flower?.id && x?.orgId == item?.fromOrganization?.id
    )

    const sellRegistrationDataFilter = state?.sellRegistrationData?.filter(
      (x: any) => x?.id != item?.id
    )

    const resultSellRegistrationFlowerFilter = state?.sellRegistrationFlower?.filter(
      (item1: any) =>
        !sellRegistrationFlowerFilter.some(
          (item2: any) => item1.flowerId === item2.flowerId && item1.orgId === item2.orgId
        )
    )

    dispatch(
      handle_variables({
        sellRegistrationFlower: resultSellRegistrationFlowerFilter,
        sellRegistrationData: sellRegistrationDataFilter,
      })
    )
  }

  const totalPrice = (returnDataType: string) => {
    let prices: number | string = 0
    for (let i = 0; i < state?.sellRegistrationData.length; i++) {
      if (state?.sellRegistrationData[i] && formik?.values?.flowerCount[i]) {
        const computing =
          formik?.values?.flowerCount[i]?.price * formik?.values?.flowerCount[i]?.amount

        prices = computing + prices
      }
    }
    if (returnDataType == 'string') {
      prices = prices.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return prices
    } else if (returnDataType == 'number') {
      return Number(prices)
    }
  }

  const totalFinalPrice = (returnDataType: string) => {
    let prices: number | string = 0
    for (let i = 0; i < state?.sellRegistrationData.length; i++) {
      if (state?.sellRegistrationData[i] && formik?.values?.flowerCount[i]) {
        const computing =
          formik?.values?.flowerCount[i]?.finalPrice * formik?.values?.flowerCount[i]?.amount

        prices = computing + prices
      }
    }
    if (returnDataType == 'string') {
      prices = prices.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return prices
    } else if (returnDataType == 'number') {
      return Number(prices)
    }
  }

  const addPromissoryNote = () => {
    setPromissoryNotes([
      ...promissoryNotes,
      {
        amount: '',
        date: '',
        number: '',
        bankId: '',
      },
    ])
  }

  const deletePromissoryNote = (index: number) => {
    const updatedRows = [...promissoryNotes]
    updatedRows.splice(index, 1)
    setPromissoryNotes(updatedRows)
  }

  const handleSelectValue = (index: any) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].select()
    }
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values, {resetForm}) => {
      dispatch(handle_variables({setSellHistoryLoading: true}))
      // console.log('values', values)
      // return
      const data = {
        cash: values.cashAmount,
        discount: (Number(totalPrice('number')) - Number(totalFinalPrice('number'))),
        buyerUserId: values.buyerUserId,
        saleHistories: values.flowerCount,
        cheques: [],
      }

      setPrintData(data)

      // if promissoryNotes is exist i want add buyerUserId in all of object
      if (promissoryNotes.length > 0) {
        promissoryNotes.map((x: any) => {
          x.buyerUserId = values.buyerUserId
        })

        data.cheques = promissoryNotes

        setPromissoryNotes([])
      }

      try {
        await dispatch(setSellHistory(data))
        handlePrint()
        toast.success('فروش با موفقیت ثبت شد')
        setManualFlowersSelected([])
        dispatch(handle_variables({sellRegistrationFlower: [], sellRegistrationData: []}))
        setDiscount(0)
        resetForm()
      } catch (error) {
        dispatch(handle_variables({setSellHistoryLoading: false}))
      }
    },
  })

  const handleCountInputChange = (event: any, ind: number, item: any) => {
    formik.handleChange(event)

    // Set the additional field value
    formik.setFieldValue(`flowerCount[${ind}].saleManagementId`, item?.id)

    // Set the price of flower
    formik.setFieldValue(`flowerCount[${ind}].price`, item?.price)

    //set the flower name for print invoice
    formik.setFieldValue(`flowerCount[${ind}].flowerName`, item?.flower?.title)

    // Set the final price of flower
    formik.setFieldValue(`flowerCount[${ind}].finalPrice`, item?.price)
  }

  const componentRef: any = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <>
      {/* <QrReader {...qrReaderProps} /> */}

      <div style={{opacity: '0', display: 'none'}}>
        <ComponentToPrint ref={componentRef} data={printData} />
      </div>

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>ثبت فروش</span>
            <QRScanner scanQr={handleQRScan} />
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
          <div
            onClick={() => {
              history.goBack()
            }}
            style={{flexDirection: 'column', cursor: 'pointer'}}
            className='d-flex justify-content-end'
          >
            بازگشت
            <i
              className='bi bi-arrow-left'
              style={{color: '#000', fontSize: '23px', textAlign: 'center'}}
            ></i>
          </div>
        </div>

        <Row className='px-5 mt-3'>
          {selectFlowerManualStatus === 0 ? (
            <Col md={2}>
              <Button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  //change state to show dropdown
                  setSelectFlowerManualStatus(1)

                  //call sell management service to recive flowers
                  const data = {
                    page: 1,
                    pageSize: 1000,
                  }
                  dispatch(getSellManagements(data))
                }}
              >
                افزودن دستی
              </Button>
            </Col>
          ) : (
            <>
              <Col md={4}>
                <DefaultDropDown
                  name='manualFlowers'
                  value={manualFlowersSelected}
                  onChange={(e: any) => {
                    //get flower id and fromOrganization id
                    const ids = e[e?.length - 1].value.split('-')

                    const scanData = {
                      FLOWER: +ids[0],
                      ORG: +ids[1],
                    }

                    handleQRScan(scanData)

                    setManualFlowersSelected(e)
                  }}
                  type='default'
                  isMulti={true}
                  placeholder='انتخاب گل'
                  options={manualFlowers}
                />
              </Col>
            </>
          )}
        </Row>
        {/* end::Header */}
        {/* begin::Body */}
        {state.flowerDetailsLoading ? (
          <div className='w-100 d-flex justify-content-center my-6'>
            <GridLoader size={5} color={'gray'} />
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='my-7'>
                {state?.sellRegistrationData?.length > 0 ? (
                  <>
                    {state?.sellRegistrationData?.map((item: any, ind: number) => {
                      return (
                        <Row key={ind}>
                          <Col md={3} className='my-3'>
                            <label className='form-label fw-bolder text-dark fs-6'>نام گل</label>
                            <input
                              type='text'
                              disabled={true}
                              value={item?.flower?.title}
                              style={{direction: 'rtl'}}
                              className={'form-control form-control-lg form-control-solid'}
                              // {...formik.setFieldValue(`flowerCount[${ind}].saleManagementId`,item.id)}
                              placeholder='نام گل'
                            />
                          </Col>
                          <Col md={1} className='my-3'>
                            <label className='form-label fw-bolder text-dark fs-6'>انبار</label>
                            <input
                              type='text'
                              disabled={true}
                              value={item?.amount}
                              className={'form-control  form-control-solid'}
                              placeholder='تعداد موجود در انبار'
                            />
                          </Col>
                          <Col md={2} className='my-3'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              قیمت(تومان)
                            </label>
                            <input
                              type='number'
                              disabled={true}
                              value={item?.price}
                              style={{direction: 'rtl'}}
                              className={'form-control form-control-lg form-control-solid'}
                              // {...formik.getFieldProps('flowerCount')}
                              placeholder='قیمت'
                            />
                          </Col>
                          <Col md={1} className='my-3'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              تعداد فروش
                            </label>
                            <input
                              type='number'
                              style={{direction: 'rtl'}}
                              className={'form-control  form-control-solid'}
                              {...formik.getFieldProps(`flowerCount[${ind}].amount`)}
                              onChange={(e) => handleCountInputChange(e, ind, item)}
                              placeholder='تعداد فروش'
                            />
                          </Col>
                          <Col md={2} className='my-3'>
                            <label className='form-label fw-bolder text-dark fs-6'>
                              قیمت نهایی(تومان)
                            </label>
                            <input
                              type='number'
                              // disabled={true}
                              defaultValue={item?.price}
                              style={{direction: 'rtl'}}
                              ref={(el) => (inputRefs.current[ind] = el)}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `flowerCount[${ind}].finalPrice`,
                                  +e.target.value
                                )
                              }}
                              className={'form-control form-control-lg form-control-solid'}
                              // {...formik.getFieldProps('flowerCount')}
                              // {...formik.getFieldProps(`flowerCount[${ind}].finalPrice`)}
                              onClick={() => handleSelectValue(ind)}
                              placeholder='قیمت نهایی'
                            />
                            <div className='text-primary'>
                              {stringPrice({
                                data: formik?.values?.flowerCount[ind]?.finalPrice,
                              })}
                            </div>
                          </Col>
                          <Col md={2} className='my-3'>
                            <label className='form-label fw-bolder text-dark fs-6'>مبلغ</label>
                            {formik?.values?.flowerCount.length > 0 &&
                            formik?.values?.flowerCount[ind] ? (
                              <div className='h-100 mt-4 ' style={{color: 'green'}}>
                                {/* {formik?.values?.flowerCount[ind]?.input1 * item?.price} */}
                                {stringPrice({
                                  data:
                                    formik?.values?.flowerCount[ind]?.amount *
                                    formik?.values?.flowerCount[ind]?.finalPrice,
                                })}
                              </div>
                            ) : null}
                          </Col>
                          <Col md={1} className='my-3 d-flex align-items-end'>
                            <button
                              onClick={() => removeRow(item)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </button>
                          </Col>
                        </Row>
                      )
                    })}
                    <Row className='d-flex justify-content-end mt-12'>
                      {/* <hr className='mt-8' /> */}
                      {/* <Col md={3} className='my-3 '>
                        <label className='form-label fw-bolder text-dark fs-6'>تخفیف</label>
                        <input
                          type='number'
                          style={{direction: 'rtl'}}
                          className={'form-control form-control-lg form-control-solid no-spinners'}
                          // {...formik.getFieldProps(`flowerCount[${ind}].amount`)}
                          onChange={(e) => setDiscount(Number(e.target.value))}
                          placeholder='مبلغ تخفیف'
                        />
                        <div className='text-danger'>
                          {stringPrice({
                            data: discount,
                          })}
                        </div>
                      </Col> */}
                      <Col md={3} className='my-3 '>
                        <div>
                          <span className=' fw-bolder text-dark fs-6 me-5'>مجموع</span>
                          <span className='fs-4 fw-bolder'>{totalPrice('string')} تومان</span>
                        </div>
                        <div className='mt-3'>
                          <span className=' fw-bolder text-dark fs-6 me-5'>تخفیف</span>
                          <span className='fs-4 fw-bolder' style={{color: '#e53935'}}>
                          {priceSlice({data: (Number(totalPrice('number')) - Number(totalFinalPrice('number'))) })} تومان
                          </span>
                        </div>
                        <div className='mt-3'>
                          <span className=' fw-bolder text-dark fs-6 me-5'> مبلغ نهایی</span>
                          <span className='fs-4 fw-bolder' style={{color: '#388e3c'}}>
                            
                            {priceSlice({data: Number(totalFinalPrice('number'))})} تومان
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <hr className='mt-8' />
                      <Col md={3} className='my-3'>
                        <label className='form-label fw-bolder text-dark fs-6'>نام خریدار</label>
                        <DefaultDropDown
                          name='flower'
                          onChange={(e: any) => {
                            formik.setFieldValue('buyerUserId', e.value)
                          }}
                          type='default'
                          placeholder='نام خریدار'
                          options={buyer}
                        />
                      </Col>
                      <Col md={3} className='my-3'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          مبلغ پرداختی نقدی
                        </label>
                        <input
                          type='number'
                          style={{direction: 'rtl'}}
                          className={'form-control form-control-lg form-control-solid'}
                          {...formik.getFieldProps('cashAmount')}
                          placeholder='مبلغ پرداختی نقدی'
                        />
                        {formik?.values?.cashAmount > 0 ? (
                          <div className='h-100 ' style={{color: 'green'}}>
                            {/* {formik?.values?.flowerCount[ind]?.input1 * item?.price} */}
                            {stringPrice({
                              data: formik?.values?.cashAmount,
                            })}
                          </div>
                        ) : null}
                      </Col>
                      <Col md={3} className='my-3'>
                        <label className='form-label fw-bolder text-dark fs-6'>مبلغ نسیه</label>
                        <div className='fs-5 fw-bolder mt-3 ms-' style={{color: '#e53935'}}>
                          {(Number(totalFinalPrice('number')) - formik.values.cashAmount)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </div>
                      </Col>
                      {/* <Col md={3} className='my-3'>
                        <label className='form-label fw-bolder text-dark fs-6'>نوع نسیه</label>
                        <Col md={12} className='my-3'>
                          <label className='form-label fw-bolder text-dark fs-6'>چک</label>
                          <input
                            className='form-check-input me-4 ms-1'
                            type='radio'
                            name='appType'
                            value='1'
                            placeholder='ali'
                            defaultChecked={true}
                            // checked={data.appBasic.appType === 'Face to Face Discussions'}
                            // onChange={() =>
                            //   updateData({
                            //     appBasic: {
                            //       appName: data.appBasic.appName,
                            //       appType: 'Face to Face Discussions',
                            //     },
                            //   })
                            // }
                          />
                          <label className='form-label fw-bolder text-dark fs-6'>حساب باز</label>
                          <input
                            className='form-check-input ms-1'
                            type='radio'
                            name='appType'
                            value='2'

                            // checked={data.appBasic.appType === 'Face to Face Discussions'}
                            // onChange={() =>
                            //   updateData({
                            //     appBasic: {
                            //       appName: data.appBasic.appName,
                            //       appType: 'Face to Face Discussions',
                            //     },
                            //   })
                            // }
                          />
                        </Col>
                      </Col> */}
                    </Row>
                    <Row className='d-flex justify-content-between'>
                      <Col md={9} className='my-3'>
                        <Button
                          onClick={() => {
                            addPromissoryNote()
                          }}
                          className='btn btn-primary fs-5 fw-bolder '
                        >
                          افزودن چک
                        </Button>
                        {promissoryNotes.map((row: any, index: any) => {
                          return (
                            <div key={index} className='my-4 d-flex'>
                              <Col md={3} className='mx-1'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  مبلغ چک
                                </label>
                                <input
                                  type='number'
                                  value={promissoryNotes[index].promissoryNoteValue}
                                  style={{direction: 'rtl'}}
                                  className={
                                    'form-control form-control-lg form-control-solid no-spinners'
                                  }
                                  // {...formik.getFieldProps(`flowerCount[${ind}].amount`)}
                                  onChange={(e) => {
                                    const updatedPromissoryNotes = [...promissoryNotes]
                                    updatedPromissoryNotes[index].amount = e.target.value
                                    setPromissoryNotes(updatedPromissoryNotes)
                                  }}
                                  placeholder='مبلغ چک'
                                />
                                <div style={{color: 'green'}}>
                                  {stringPrice({
                                    data: promissoryNotes[index].amount,
                                  })}
                                </div>
                              </Col>

                              <Col md={3} className='mx-1'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  شماره چک
                                </label>
                                <input
                                  type='number'
                                  style={{direction: 'rtl'}}
                                  className={
                                    'form-control form-control-lg form-control-solid no-spinners'
                                  }
                                  // {...formik.getFieldProps(`flowerCount[${ind}].amount`)}
                                  onChange={(e) => {
                                    const updatedPromissoryNotes = [...promissoryNotes]
                                    updatedPromissoryNotes[index].number = e.target.value
                                    setPromissoryNotes(updatedPromissoryNotes)
                                  }}
                                  value={promissoryNotes[index].promissoryNoteNumber}
                                  placeholder='شماره چک'
                                />
                              </Col>
                              <Col md={2} className='mx-1'>
                                <label className='form-label fw-bolder text-dark fs-6'>بانک</label>
                                <DefaultDropDown
                                  name='bank'
                                  onChange={(e: any) => {
                                    const updatedPromissoryNotes = [...promissoryNotes]
                                    updatedPromissoryNotes[index].bankId = e.id
                                    setPromissoryNotes(updatedPromissoryNotes)
                                  }}
                                  type='default'
                                  placeholder='بانک'
                                  options={financS?.banks}
                                />
                              </Col>
                              <Col md={3} className='mx-1'>
                                <label className='form-label fw-bolder text-dark fs-6'>
                                  تاریخ چک
                                </label>
                                <DefaultDatePicker
                                  placeholder={'تاریخ چک'}
                                  from={(e: string) => {
                                    const updatedPromissoryNotes = [...promissoryNotes]
                                    updatedPromissoryNotes[index].date = e
                                    setPromissoryNotes(updatedPromissoryNotes)
                                  }}
                                  type='single'
                                  minDate={true}
                                />
                              </Col>
                              <Col md={1} className='mx-1 d-flex mt-9'>
                                <button
                                  onClick={() => deletePromissoryNote(index)}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              </Col>
                            </div>
                          )
                        })}
                      </Col>
                      <Col md={3} className='my-3'>
                        <Button
                          // onClick={() => {
                          //   dispatch(handle_variables({changeFlowersPriceModalOpen: true}))
                          // }}
                          type='submit'
                          className='btn btn-primary fs-5 fw-bolder w-100'
                        >
                          {state?.setSellHistoryLoading ? (
                            <BeatLoader color='white' size={4} />
                          ) : (
                            'ثبت نهایی'
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  'لیست فروش خالی است'
                )}
              </div>
            </form>
            {/* end::Table container */}
          </div>
        )}

        {/* begin::Body */}
      </div>
    </>
  )
}

export default SellRegistration
