import {FC, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'

import {Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import type {UploadProps} from 'antd'
import {Button as AntdBtn, Image, message, Upload} from 'antd'
import {toast, ToastContainer} from 'react-toastify'
import DefaultDropDown from '../../../components/DefaultDropDown'
import {BeatLoader, GridLoader} from 'react-spinners'
import { getGrades, gradeState, handle_variables, setGrade, updateGrade } from '../redux/gradeSlice'
import async from 'react-select/dist/declarations/src/async/index'






const GradeDefinesModal = ({
  edit = false,
  defaultValue,
}: {
  edit?: boolean
  defaultValue?: any
}) => {
  const state = useSelector(gradeState)
  const dispatch = useDispatch()




  const Schema = Yup.object().shape({
    grade: Yup.string().required('نام درجه نمی تواند خالی باشد'),
    rank: Yup.string().required(' رتبه نمی تواند خالی باشد'),
  })

  const initialValues = {
    grade:
      edit && state.gradeUpdatedefaultValue?.title ? state.gradeUpdatedefaultValue?.title : '',
      rank:
      edit && state.gradeUpdatedefaultValue?.rank ? state.gradeUpdatedefaultValue?.rank : '',
  }

 

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async(values) => {
      console.log('herrere', values)
      dispatch(handle_variables({gradeAddLoading: true}))

      //Condition for create and update
      if (edit) {
        //update
        const data = {
          title: values.grade,
          rank : values.rank 
        }
        const id = state.gradeUpdatedefaultValue.id
      
       await dispatch(updateGrade({id,data}))
      } else {
        //create

        const data = {
          title: values.grade,
          rank: values.rank
        }

       await dispatch(setGrade(data))
      }
     
      dispatch(getGrades({page: state.gradePagination.page, pageSize: state.gradePagination.limit}))
       
    },
  })

  return (
    <Modal
      size='lg'
      show={state?.addGradeModalOpen}
      onHide={() => dispatch(handle_variables({addGradeModalOpen: false}))}
    >
      <Modal.Header>
        <Modal.Title>تعریف درجه</Modal.Title>
      </Modal.Header>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <Modal.Body>
          {state.gradeUpdatedeDataLoading ? (
            <div className='d-flex justify-content-center'>
              {' '}
              <GridLoader size={5} color={'gray'} />
            </div>
          ) : (
            <Row>
              <Col md={6}>
                <label className='form-label fw-bolder text-dark fs-6'>نام درجه</label>

                <input
                  className={'form-control form-control-lg form-control-solid'}
                  {...formik.getFieldProps('grade')}
                  placeholder='نام درجه'
                />
                {formik.touched.grade && formik.errors.grade && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='invalid-error-text' role='alert'>
                        {formik.errors.grade}
                      </span>
                    </div>
                  </div>
                )}
              </Col>

              <Col md={6}>
                <label className='form-label fw-bolder text-dark fs-6'>نام درجه</label>

                <input
                  className={'form-control form-control-lg form-control-solid'}
                  {...formik.getFieldProps('rank')}
                  placeholder='رتبه'

                />
                {formik.touched.rank && formik.errors.rank && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='invalid-error-text' role='alert'>
                        {formik.errors.rank}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
             
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              dispatch(handle_variables({addGradeModalOpen: false}))
            }}
            variant='secondary'
          >
            انصراف
          </Button>
          <Button disabled={state?.gradeAddLoading ? true : false} type='submit' variant='primary'>
            {state?.gradeAddLoading ? (
              <div>
                <BeatLoader size={6} color={'white'} />
              </div>
            ) : (
              'ثبت'
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default GradeDefinesModal
