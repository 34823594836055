import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import SellPage from '../modules/sell/SellPage'
import FlowerPage from '../modules/flower/FlowerPage'
import ReturnFlowerPage from '../modules/returnFlower/ReturnFlowerPage'
import CooprationPage from '../modules/partners/CooperationPage'
import GradePage from '../modules/grade/GradePage'
import FinancPage from '../modules/financ/FinancPage'
import UserManagmentPage from '../modules/userManagment/UserManagmentPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/sell' component={SellPage} />
        <Route path='/return-flower' component={ReturnFlowerPage} />
        <Route path='/grade' component={GradePage} />
        <Route path='/crafted/pages/flower' component={FlowerPage} />
        <Route path='/cooperation' component={CooprationPage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/accounts' component={AccountPage} />
        <Route path='/user-managment' component={UserManagmentPage} />
        <Route path='/financ' component={FinancPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
