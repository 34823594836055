import {applyMiddleware, combineReducers, configureStore, createStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import {reduxBatch} from '@manaflair/redux-batch'
import {persistStore} from 'redux-persist'
import { rootSaga} from './RootReducer'
import flowerSlice from '../../app/modules/flower/redux/flowerSlice'
import mainSlice from '../../app/redux/mainSlice'
import AuthSlice from '../../app/modules/auth/redux/AuthSlice'
import storage from 'redux-persist/lib/storage'
import persistReducer from 'redux-persist/es/persistReducer'
import thunk from 'redux-thunk'
import AccountSlice from '../../app/modules/accounts/Redux/AccountSlice'
import sellSlice from '../../app/modules/sell/redux/sellSlice'
import cooperationSlice from '../../app/modules/partners/Redux/cooperationSlice'
import gradeSlice from '../../app/modules/grade/redux/gradeSlice'
import financSlice from '../../app/modules/financ/redux/financSlice'
import UserManagmentSlice from '../../app/modules/userManagment/redux/UserManagmentSlice'

// const sagaMiddleware = createSagaMiddleware()
// const middleware = [
//   ...getDefaultMiddleware({
//     immutableCheck: false,
//     serializableCheck: false,
//     thunk: true,
//   }),
//   sagaMiddleware,
// ]

// const store = configureStore({
//   reducer: rootReducer,
//   middleware,
//   devTools: process.env.NODE_ENV !== 'production',
//   enhancers: [reduxBatch],
// })

// export type AppDispatch = typeof store.dispatch

// /**
//  * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
//  * @see https://github.com/rt2zz/redux-persist#persistor-object
//  */
// export const persistor = persistStore(store)

// sagaMiddleware.run(rootSaga)

export const rootReducer = combineReducers({
  // auth: auth.reducer,
  flower : flowerSlice,
  main :mainSlice,
  auth:AuthSlice,
  account:AccountSlice,
  sell : sellSlice,
  userManagment : UserManagmentSlice,
  cooperation : cooperationSlice,
  grade:gradeSlice,
  financ:financSlice
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'] // which reducer want to store
};
const middleware = applyMiddleware(thunk);

const pReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(pReducer,middleware);
export const persistor = persistStore(store);

export default store
