import {FC, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import {handle_variables, mainState} from '../redux/mainSlice'

const DeleteConfirmModal = ({
  text = '',
  show,
  onDelete = () => {},
  close = () => {},
}: {
  text?: string
  show?: boolean
  onDelete?: () => void
  close?: () => void
}) => {
  const state = useSelector(mainState)
  const dispatch = useDispatch()

  return (
    <Modal size='lg' show={show} onHide={() => close()}>
      {/* <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <Row>
          <p>{text}</p>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            close()
          }}
          variant='secondary'
        >
          انصراف
        </Button>
        <Button onClick={() => onDelete()} variant='danger'>
          حذف
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteConfirmModal
