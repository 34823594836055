import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'
import {
  delete_flower,
  delete_organization_flower,
  get_flower_update_data,
  get_flowers,
  get_organization_flowers,
  set_flower,
  set_organization_flower,
  update_flower,
} from './flowerApi'
import {toast} from 'react-toastify'

interface sendData {
  email: string
  password: string
}

interface CustomError extends AxiosResponse {
  response: {
    data: {
      message: string
    }
  }
}

const initialState = {
  flowers: [],
  flowerTableLoading: false,
  addFlowerModalOpen: false,
  flowerAddLoading: false,
  flowerUpdatedefaultValue: {},
  flowerUpdatedeDataLoading: false,
  flowerPagination: {
    page: 1,
    limit: 10,
  },

  organizationFlowers: [],
  organizationFlowerTableLoading: false,
  addOrganizationFlowerModalOpen: false,
  organizationFlowerAddLoading: false,
  organizationFlowerPagination: {
    page: 1,
    limit: 10,
  },

  selectExhibitorModalOpen:false
}

///////////////////////// flower define ////////////////////////////
export const getFlower = createAsyncThunk('account/getFlower', async (data: any) => {
  try {
    const response = await get_flowers(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

export const setFlower = createAsyncThunk('account/setFlower', async (data: any) => {
  try {
    const response = await set_flower(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

export const deleteFlower = createAsyncThunk('account/deleteFlower', async (id: number) => {
  try {
    const response = await delete_flower(id)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

export const getFlowerUpdateData = createAsyncThunk(
  'account/getFlowerUpdateData',
  async (id: number) => {
    try {
      const response = await get_flower_update_data(id)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
    }
  }
)

export const updateFlower = createAsyncThunk('account/updateFlower', async (data: any) => {
  try {
    const response = await update_flower(data.id, data.data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
  }
})

//////////////////////// Organization Flower //////////////////////////

export const getOrganizationFlower = createAsyncThunk(
  'account/getOrganizationFlower',
  async (data: any) => {
    try {
      const response = await get_organization_flowers(data.page, data.pageSize,data.organizationId)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
    }
  }
)

export const setOrganizationFlower = createAsyncThunk(
  'account/setOrganizationFlower',
  async (data: any) => {
    try {
      const response = await set_organization_flower(data)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
    }
  }
)

export const deleteOrganizationFlower = createAsyncThunk(
  'account/deleteOrganizationFlower',
  async (id: number) => {
    try {
      const response = await delete_organization_flower(id)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
    }
  }
)

const flowerSlice = createSlice({
  name: 'flower',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFlower.pending, (state, action) => {
        state.flowerTableLoading = true
      })
      .addCase(getFlower.fulfilled, (state, action) => {
        if (action.payload) {
          state.flowerPagination = action.payload.data.pagination
          state.flowerTableLoading = false
          state.flowers = action.payload.data.data
        }
      })
      .addCase(setFlower.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success('با موفقیت گل جدید اضافه شد')
          state.flowerAddLoading = false
          state.addFlowerModalOpen = false
        }
      })
      .addCase(deleteFlower.fulfilled, (state, action) => {
        //  state.roles=state.roles.filter(role => role.id !== action.payload)
        toast.success('حذف با موفقیت انجام شد')
        // get_role()
      })
      .addCase(getFlowerUpdateData.pending, (state, action) => {
        state.flowerUpdatedeDataLoading = true
      })
      .addCase(getFlowerUpdateData.fulfilled, (state, action: any) => {
        state.flowerUpdatedeDataLoading = false
        state.flowerUpdatedefaultValue = action.payload.data.data
      })
      .addCase(updateFlower.fulfilled, (state, action: any) => {
        state.addFlowerModalOpen = false
        state.flowerAddLoading = false
      })
      .addCase(updateFlower.rejected, (state, action: any) => {
        state.flowerAddLoading = false
      })
      .addCase(getOrganizationFlower.pending, (state, action) => {
        state.organizationFlowerTableLoading = true
      })
      .addCase(getOrganizationFlower.fulfilled, (state, action) => {
        if (action.payload) {
          state.organizationFlowerPagination = action.payload.data.pagination
          state.organizationFlowerTableLoading = false
          state.organizationFlowers = action.payload.data.data
        }
      })
      .addCase(setOrganizationFlower.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success('با موفقیت گل جدید اضافه شد')
          state.organizationFlowerAddLoading = false
          state.addOrganizationFlowerModalOpen = false
        }
      })
      .addCase(deleteOrganizationFlower.fulfilled, (state, action) => {
        //  state.roles=state.roles.filter(role => role.id !== action.payload)
        toast.success('حذف با موفقیت انجام شد')
        // get_role()
      })
  },
})

export const {handle_variables} = flowerSlice.actions

export const flowerState = (state: any) => state.flower

export default flowerSlice.reducer
