import {useState} from 'react'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {SelectOption} from '../typescript/interface'

const DefaultDropDown = ({
  type,
  placeholder = '',
  options = [],
  disabled,
  name,
  onChange,
  isMulti,
  defaultValue,
  notCloseMenu,
  value ,
}: {
  type: string
  placeholder?: string
  options?: SelectOption[]
  disabled?: boolean
  name?: string
  formik?: any
  onChange?: any
  isMulti?: boolean
  defaultValue?: any
  notCloseMenu ?: boolean
  value ?: any 
}) => {

  const [searchValue, setSearchValue] = useState('')
  const [inputValue, setInputValue] = useState('');

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: 'none', // Remove the border
      width: '100%',
      paddingRight: '10px',
      backgroundColor: state.isDisabled ? '#eceff1' : '#f5f8fa', // Set the background color
      boxShadow: state.isFocused ? null : null,
      minHeight: '42px',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      color: '#a1a5b7',
    }),
  }


  const handleInputChange = (input : string, { action }:{action : any}) => {
    if (action === 'input-change') {
      setInputValue(input);
    }
  };

  const handleMenuClose = () => {
    setInputValue('');
  };

  switch (type) {
    case 'default':
      return (
        <Select
          getOptionLabel={(options: any) => options?.title || options?.label}
          getOptionValue={(options: any) => options?.id || options?.value}
          className='default-drop-down'
          name={name}
          onChange={(e: any) => {
            onChange(e)
          }}
          placeholder={placeholder}
          styles={customStyles}
          closeMenuOnSelect={notCloseMenu ? false : true}
          hideSelectedOptions={false}
          inputValue={isMulti ? inputValue : undefined}
          onInputChange={isMulti ? handleInputChange : undefined} 
          onMenuClose={isMulti ? handleMenuClose : undefined}
          isMulti={isMulti}
          options={options}
          defaultValue={defaultValue}
          isDisabled={disabled}
          value={value}
        />
      )
      break
    case 'async':
      return (
        <AsyncSelect
          className='default-drop-down'
          name={name}
          placeholder={placeholder}
          styles={customStyles}
          cacheOptions
          isMulti={isMulti}
          isDisabled={disabled}
          defaultOptions={options}
          onChange={(e: any) => {
            onChange(e)
          }}
        />
      )
      break
    default:
      return <div>Error: Unsupported dropdown type "{type}". Please provide a valid type.</div>
      break
  }
}

export default DefaultDropDown
