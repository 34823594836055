/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import GridLoader from 'react-spinners/GridLoader'
import {authState, handle_variables, login} from '../redux/AuthSlice'
import { refreshCaptcha, handle_variables as updateMainState} from '../../../redux/mainSlice'
import {converPersianNumberToEnglish} from '../../../functions/convertPersianNumberToEnglish'
import Captcha from '../../../components/Captcha'
import { mainState } from '../../../redux/mainSlice'
import { unwrapResult } from '@reduxjs/toolkit'

const loginSchema = Yup.object().shape({
  input: Yup.string().required('شماره موبایل یا ایمیل نمی تواند خالی باشد'),
  password: Yup.string()
    // .min(8, 'رمز عبور حداقل باید ۸ کاراکتر باشد')
    .required('رمز عبور نمی تواند خالی باشد'),
})

const initialValues = {
  input: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  // const [loading, setLoading] = useState(false)
  const captchaRef : any = useRef(null);
  const state = useSelector(authState)
  const mainS = useSelector(mainState)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async(values, {setStatus, setSubmitting}) => {
      dispatch(handle_variables({loginLoading: true}))
      try {
        const data = {
          input: values.input,
          password: values.password,
          token : mainS.captchaToken
        }

        const resultAction : any = await dispatch(login(data))
        unwrapResult(resultAction);
      } catch (error) {
        dispatch(refreshCaptcha())
      }
     


      // setTimeout(() => {

      //   login(values.email, values.password)
      //     .then(({data: {accessToken}}) => {
      //       setLoading(false)
      //       dispatch(auth.actions.login(accessToken))
      //     })
      //     .catch(() => {
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>ورود به سامانه رامینو</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          قبلا ثبت نام نکرده اید؟{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            ثبت نام
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>شماره تلفن یا ایمیل</label>
        <input
          style={{direction: 'rtl'}}
          placeholder='شماره تلفن یا ایمیل'
          {...formik.getFieldProps(converPersianNumberToEnglish('input'))}
          className={'form-control form-control-lg form-control-solid'}
          type='text'
          name='input'
          autoComplete='off'
        />
        {formik.touched.input && formik.errors.input && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='invalid-error-text' role='alert'>
                {formik.errors.input}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>رمز عبور</label>
            {/* end::Label */}
            {/* begin::Link */}

            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          placeholder='رمز عبور'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={'form-control form-control-lg form-control-solid'}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='invalid-error-text' role='alert'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
        {/* <Link
          to='/auth/forgot-password'
          className='link-primary fs-6 fw-bolder'
          style={{marginLeft: '5px', marginTop: '10px !important'}}
        >
          رمز خود را فراموش کرده ام
        </Link> */}
      </div>


      <div className='fv-row mb-10'>
        <Captcha  />
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          // disabled={formik.isSubmitting || !formik.isValid}
        >
          {!state.loginLoading && <span className='indicator-label'>ورود</span>}
          {state.loginLoading && (
            <span className='indicator-progress d-flex align-items-center justify-content-center'>
              <GridLoader size={4} color={'white'} />
            </span>
          )}
        </button>

        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
