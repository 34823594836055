import {FC, useEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {addSellRequest} from '../../../../typescript/interface'
import {useDispatch, useSelector} from 'react-redux'
import {
  getSellManagements,
  getSellRequests,
  handle_variables,
  sellState,
  setSellRequest,
  updateSellManagement,
  updateSellRequest,
} from '../../redux/sellSlice'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {authState} from '../../../auth/redux/AuthSlice'
import {flowerState, getOrganizationFlower} from '../../../flower/redux/flowerSlice'
import {cooperationState, getCoopRequest} from '../../../partners/Redux/cooperationSlice'
import {stringPrice} from '../../../../functions/stringPrice'
import {BeatLoader, GridLoader} from 'react-spinners'
import {toast} from 'react-toastify'

var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  fixNumbers = function (str: any) {
    if (typeof str === 'string') {
      for (var i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
      }
    }
    return str
  }

const PriceModal = ({edit = false, defaultValue}: {edit?: boolean; defaultValue?: any}) => {
  const state = useSelector(sellState)
  const dispatch = useDispatch()

  const Schema = Yup.object().shape({
    price: Yup.string(),
  })

  const initialValues = {
    price: defaultValue?.price ? defaultValue?.price : undefined,
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values) => {
      dispatch(handle_variables({changePriceLoading: true}))

      const data = {
        price: values.price,
      }
      const id = defaultValue.id

      try {
        await dispatch(updateSellManagement({id, data}))
        toast.success('تغییر قیمت با موفقیت انجام شد')
        dispatch(getSellManagements({page: 1, pageSize: 10}))
      } catch (err) {
        throw err
      }
    },
  })

  return (
    <>
      <Modal
        size='lg'
        show={state?.changePriceModalOpen}
        onHide={() => dispatch(handle_variables({changePriceModalOpen: false}))}
      >
        <Modal.Header>
          <Modal.Title>تغییر قیمت</Modal.Title>
        </Modal.Header>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body>
            <>
              <Row>
                <Col lg={12} className='mt-2'>
                  <label className='form-label fw-bolder text-dark fs-6'>قیمت فروش(تومان)</label>
                  <input
                    maxLength={9}
                    onKeyUp={(e: any) => {
                      const value = fixNumbers(e.target.value)
                      if (isNaN(+value)) {
                        e.target.value = e.target.value.slice(0, e.target.value.length - 1)
                      }
                    }}
                    className={'form-control form-control-lg form-control-solid'}
                    {...formik.getFieldProps('price')}
                    placeholder='قیمت(تومان)'
                  />
                  {formik.values.price != '' && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='text-success' role='alert'>
                          {stringPrice({data: formik.values.price})}
                        </span>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(handle_variables({changePriceModalOpen: false}))
              }}
              variant='secondary'
            >
              انصراف
            </Button>
            <Button type={state?.changePriceLoading ? 'button' : 'submit'} variant='primary'>
              {state?.changePriceLoading ? <BeatLoader size={3} color='white' /> : ' ثبت'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default PriceModal
