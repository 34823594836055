import {FC, useEffect, useRef, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {useDispatch, useSelector} from 'react-redux'
import {
  flowerState,
  getFlower,
  getOrganizationFlower,
  handle_variables,
  setFlower,
  setOrganizationFlower,
  updateFlower,
} from '../../redux/flowerSlice'
import {Form, Formik, useFormik} from 'formik'
import * as Yup from 'yup'
import {flowerDefineDefault} from '../../../../typescript/interface'
import type {UploadProps} from 'antd'
import {Button as AntdBtn, Image, message, Upload} from 'antd'
import {toast, ToastContainer} from 'react-toastify'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {authState} from '../../../auth/redux/AuthSlice'
import {cooperationState, getCoopRequest} from '../../../partners/Redux/cooperationSlice'
import ReactToPrint from 'react-to-print'
import QrPrint from './QrPrint'

const SelectExhibitorModal = ({flower, org}: {flower?: any; org: any}) => {
  const state = useSelector(flowerState)
  const coopS = useSelector(cooperationState)
  const authS = useSelector(authState)
  const dispatch = useDispatch()

  const [exhibitor,setExhibitor]=useState([])

  const componentRef: any = useRef(null)

  const Schema = Yup.object().shape({
    exhibitor: Yup.string().required('نام گل نمی تواند خالی باشد'),
  })

  const initialValues = {
    exhibitor: '',
  }

  useEffect(() => {
    const datacoop = {
      page: 1,
      pageSize: 50,
    }
    dispatch(getCoopRequest(datacoop))
  }, [])

  const organization = () => {
    if (coopS?.coops.length > 0) {
      const filteredData: any = coopS?.coops.filter((item: any) => item.cooperationStatusId == 1)

      const data: any = []

      if (filteredData.length > 0) {
        filteredData.map((x: any) => {
          if (authS.user.organizationId != x.fromOrganization.id) {
            data.push(x.fromOrganization)
          } else {
            data.push(x.toOrganization)
          }
        })
        return data
      }

      return false
    }
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: (values) => {
    
    },
  })

  return (
    <>
      <div ref={componentRef}>
        <QrPrint flower={flower} org={org} exhibitor={exhibitor} />
      </div>

      <Modal
        size='lg'
        show={state?.selectExhibitorModalOpen}
        onHide={() => dispatch(handle_variables({selectExhibitorModalOpen: false}))}
      >
        <Modal.Header>
          <Modal.Title>انتخاب غرفه دار</Modal.Title>
        </Modal.Header>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body>
            <Row>
              <Col md={6}>
                <label className='form-label fw-bolder text-dark fs-6'>نام غرفه</label>
                <DefaultDropDown
                  name='exhibitor'
                  onChange={(e: any) => {
             
                    setExhibitor(e)
                  }}
                  isMulti={true}
                  type='default'
                  placeholder='نام غرفه'
                  options={organization()}
                />
                {formik.touched.exhibitor && formik.errors.exhibitor && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='invalid-error-text' role='alert'>
                        {formik.errors.exhibitor}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(handle_variables({selectExhibitorModalOpen: false}))
              }}
              variant='secondary'
            >
              انصراف
            </Button>
            <ReactToPrint
              trigger={() => <Button style={{padding: '8px 25px'}}>دریافت qr</Button>}
              content={() => componentRef.current}
            />
            {/* <Button type='submit' variant='primary'>
              ثبت
            </Button> */}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default SelectExhibitorModal
