export const priceSlice = ({data}: {data: string | number}) => {
  let partLength = 3

  if (typeof data === 'number') {
    data = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    data = data.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return data
}
